import React from 'react'
import './index.css'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import { fetchAccountData, fetchUserFeatures,fetchUsedQuota, getPendingTransaction } from '../../Query/Account'
import { searchUsername, imageHandler, stringHandler, addMember, cancelSubscription, renewSubscription, renameLabelStart, renameLabelFinish, deleteMember, getTotalFeatures } from './action'
//import moment from 'moment'
import { postEvent3 } from '../../Function/GoogleAnalitycs'
import Feedback from '../../Function/Feedback'
import MountingComponent from '../../Function/MountingComponent'
import {buyMarker} from '../Plans/action'

let context = null

class Overview extends React.Component{

    constructor(props){
        super(props)

        if(window.vuplex ){
            props.history.replace('/Auth/Login')
        }

        this.rename = false
        this.state = {
            AccountData: {
                Name:"",
                Email:"",
                Bio:"",
                Username:"",
                ProfilePictureURL:""
            },
            plan_status:false,
            plan_type:"Personal",
            plan_active:true,
            educationMemberDialog:false,
            member_array:[],
            list_member_array:[{...this.props.redux.AccountData,role:'Admin'}],
            totalQuota:0,
            usedQuota:0,
            plans:[],
            linked_plans:[],
            adminMode:0,
            numProject:0,
            sharingQuota:0,
            memberCount:1,
            loading:false,
            quotaCaption:'',
            errorDialog:false,
            errorMessage:'',
            cancelTemp:[],
            pendingArray:[],
            activeEduPlan:0,
            markerVisibility:false,
            eduPlansCounter:0,
            componentStatus:false,
            markerCount:0,
            memberDialog:false,
            markerQty:0,
            choosenMember:{
                Username:'',
                FirebaseID:''
            },
            searchContainer:false,
            markerUsed:0,
            userTotalQuota:0,
            userTotalUsedQuota:0,
            userTotalMarker:0
        }
    }

    componentDidMount(){
        context = this
        MountingComponent(true,this)
        postEvent3('Open Page','Overview','')
        if(this.props.redux.AccountData.FirebaseID !== ''){
            fetchAccountData(this)
            fetchUserFeatures(this)
            fetchUsedQuota(this)
            getPendingTransaction(this)
            getTotalFeatures(context)
        } else {
            this.props.history.replace('/Auth/Login')
        }

        if(this.props.location.search.includes('buyMarker=true')){
            context.props.history.push('/Marker')
        }

        
        
    }

    componentWillUnmount(){
        MountingComponent(false,this)
    }

    AvailablePlansContainer = (item,index) => {
        var render = null
        var memberCount = 0
        const {eduPlansCounter} = this.state
        try {
            memberCount = item.member.length
        } catch (error) {
            
        }
        render = (
            <div className="purchased-plan" key={index}>
                <div className="card pp">
                    <div className="plan-class edu">
                        {item.plans_type}
                    </div>
                    <h2 className="plan-title pp">
                        {item.plans_name}
                    </h2>

                    {item.plans_type === 'Education'?eduPlansCounter>1?(
                        <div style={{position:'relative'}}>
                            <input 
                                // onFocus={()=>renameLabelStart(this)}
                                onChange={()=>renameLabelStart(this)}
                                onBlur={(event)=>renameLabelFinish(this,item,event.target.value,index)}
                                className='plan-notes'
                                placeholder='Add Notes Here'
                                defaultValue={item.label}
                            />

                            <div id={'rename-notif'+index} className='rename-success hidden'>
                                Updated
                            </div>
                        </div>
                        
                        
                    ):null:null}

                    {

                    }
                    
                    {(item.plans_type === 'Education' || item.plans_type === 'School')?<p className="cur-feature-detail">Member: <strong> {memberCount}/{item.sharing_users.sharing_users}</strong></p>:null}
                    
                    {(item.plans_type === 'Education' || item.plans_type === 'School')?(
                        <div className="div-block-3">
                            <div  
                                // href="# "
                                onClick={()=>{
                                    postEvent3('Education Event','View Member List Adder','')
                                    
                                    this.setState({memberDialog:true,activeEduPlan:index})
                                }}
                                className="add-user cursor-class w-inline-block">
                                <div>
                                    Add User
                                </div>
                            </div>
                        </div>
                    ):null}

                    <div className="separator variheight"/>
                    {!item.plans_status?(
                        <div 
                        // href="# " 
                        onClick={()=>renewSubscription(item,this.props.redux.FirebaseToken)}
                        className="button small w-inline-block">
                            <div>
                                <strong>Renew Plan</strong>
                            </div>
                        </div>
                    ):null}
                    <div className="separator _5px"/>
                    {(item.plans_name !== 'FREE' && item.plans_name !== 'Custom Marker')?(
                        <div 
                            href="# "
                            onClick={()=>{
                                
                                this.setState({
                                    errorDialog:true,
                                    errorMessage:'Are you sure want to cancel '+item.plans_name+' PLAN?',
                                    cancelTemp:[item]
                                })
                            }}
                            className="button danger extra-small w-inline-block">
                            <div>
                                <strong>Cancel Plan</strong>
                            </div>
                        </div>
                    ):null}
                </div>
                
            </div>
        )

        if(item.plans_type === 'Miscellaneous'){
            render = null
        }

        return render
    }

    LinkedPlansContainer = (item,index) => {
        var render = null
        render = (
            <div className="purchased-plan" key={index}>
                <div className="card pp">
                    <div className="plan-class edu">
                        Education</div>
                    <h2 className="plan-title pp">
                        {item.plans_name}
                    </h2>
                    <div className="plan-specss">
                        <div className="managed-by-label">
                            Managed By</div>
                        <div className="managed-by-value">
                            <strong className="bold-text-3">
                                {item.Name}</strong> <br/>
                                @{item.Username}
                        </div>
                    </div>
                    <div className="separator variheight">
                    </div>
                </div>
            </div>
        )
        return render
    }


    MemberDialog = () => {
        const {memberDialog} = this.state
        var render = null
        var sharingQuota = 0
        var member = []
        var memberCount = 0
        try {
            member = this.state.plans[this.state.activeEduPlan]['member']
            memberCount = member.length
            sharingQuota = this.state.plans[this.state.activeEduPlan]['sharing_users']['sharing_users']
            if(memberCount===sharingQuota){
                //console.log(memberCount)
                document.getElementById('input-member').value = sharingQuota+' Members quota has been fully used'
                document.getElementById('input-member').disabled = true
            } else {
                document.getElementById('input-member').defaultValue = ''
                document.getElementById('input-member').disabled = false
            }
        } catch (error) {
            
        }
        if(memberDialog){
            var {searchContainer} = this.state
            render = (
                <div className='popup-overlay'>
                    <div className='dialog-container member-dialog'>
                        <div className='dialog-header'>
                            <img 
                                alt=""
                                onClick={()=>{
                                    
                                    this.setState({
                                        memberDialog:false
                                    })
                                }}
                                className='marker-exit' 
                                src='https://uploads-ssl.webflow.com/5daa78035197cf51c1bfb151/5e420ffb1674159cc51e1a68_close%20modal.svg'/>
                            Manage Membership
                        </div>
                        
                        <div className='member-dialog1'>
                            <input 
                                autoComplete="off"
                                
                                id='input-member'
                                defaultValue={this.state.choosenMember.Username}
                                onChange={(event)=>searchUsername(event.target.value,context)}
                                className="member-dialog1-1"
                                placeholder="Search username"
                                onFocus={()=>this.setState({searchContainer:true})}
                                onBlur={()=>setTimeout(()=>{
                                    this.setState({searchContainer:false})   
                                },500)}
                            />

                            {/* <button 
                                onClick={()=>addMember(this.state.choosenMember,this)}
                                className='member-dialog1-2'>
                                Add
                            </button> */}
                        </div>

                        {searchContainer?(
                            <div className='member-search'>
                                {this.state.member_array.map((item,index) => this.ListComponentHandler(item,index))}    
                            </div>
                        ):null}

                        <div className='member-dialog-list' style={memberCount===1?{opacity:0.2}:null}>
                            {memberCount>0?member.map((item,index) => this.ListMemberComponentHandler(item,index)):null}
                        </div>
                        
                        {memberCount===1?(
                            <div className='empty-member'> 
                                <img className='add-member-guide' alt='guide' src={require('../../Assets/icon/invite_user_arrow.svg')}/>

                                <div className='member-row3'>
                                    <img className='add-member-btn' alt='add-btn' src={require('../../Assets/icon/invite_user.svg')}/>
                                    <p className='add-member-caption'>Invite your pupils and unleash the power of AR in your classroom</p>
                                </div>
                            </div>
                        ):null}

                        
                    </div>
                </div>
            )
        }

        return render
    }

    EducationMemberContainer = () => {
        var render = null 
        var {sharingQuota} = this.state
        var memberCount = 0

        var member = []
        try {
            member = this.state.plans[this.state.activeEduPlan]['member']
            memberCount = member.length
            sharingQuota = this.state.plans[this.state.activeEduPlan]['sharing_users']['sharing_users']
        } catch (error) {
            
        }
        render = (
            <div 
                onClick={()=>{
                    setTimeout(()=>{
                        
                        this.setState({educationMemberDialog:false})
                    },500)
                }} 
                className={this.state.educationMemberDialog?'member-list-popup-container':'member-list-popup-container-exit'}
                
                >
                <div 
                    onClick={(event)=>event.stopPropagation()}
                    className={this.state.educationMemberDialog?'member-container':'member-container-exit'}
                >
                    <div className="member-row1">
                        <p className='member-row1-item1'>Member</p>
                        <p className='member-row1-item2'>{memberCount}/{sharingQuota}</p>
                    </div>

                    <div className="member-row2">
                        <input 
                            className='member-row2-item1' 
                            placeholder='Enter username'
                            onChange={(event)=>searchUsername(event.target.value,context)}
                        />
                        <button 
                            onClick={()=>{
                                
                                this.setState({
                                    educationMemberDialog:false,
                                    educationListMemberDialog:true
                                })
                            }}
                            className='member-row2-item2'>
                            Show Member
                        </button>
                    </div>

                    {this.state.member_array.length === 0? (
                        <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                            <img className='add-member-guide' alt='guide' src={require('../../Assets/icon/invite_user_arrow.svg')}/>

                            <div className='member-row3'>
                                <img className='add-member-btn' alt='add-btn' src={require('../../Assets/icon/invite_user.svg')}/>
                                <p className='add-member-caption'>Invite your pupils and unleash the power of AR in your classroom</p>
                            </div>
                        </div>
                    ) : null }

                    
                    <div className='user-list-container'>
                        {this.state.member_array.map((item,index) => this.ListComponentHandler(item,index))}
                    </div>


                </div>
            </div>
        )

        return render
    }

    EducationListMemberContainer = () => {
        var render = null 
        var {sharingQuota} = this.state
        var member = []
        var memberCount = 0
        try {
            member = this.state.plans[this.state.activeEduPlan]['member']
            memberCount = member.length
            sharingQuota = this.state.plans[this.state.activeEduPlan]['sharing_users']['sharing_users']
        } catch (error) {
            
        }
        render = (
            <div 
                onClick={()=>{
                    setTimeout(()=>{
                        
                        this.setState({educationListMemberDialog:false})
                    },500)
                }} 
                className={this.state.educationListMemberDialog?'member-list-popup-container':'member-list-popup-container-exit'}
                >
                <div 
                    onClick={(event)=>event.stopPropagation()}
                    className={this.state.educationListMemberDialog?'member-container':'member-container-exit'}
                >
                    <div className="member-row4">
                        <p className='member-row4-item1'>Member</p>
                        <img 
                            onClick={()=>{
                                this.setState({
                                    educationListMemberDialog:false,
                                    educationMemberDialog:true
                                })
                            }}
                            className='add-member-btn2' 
                            alt='' src={require('../../Assets/icon/invite_user.svg')}
                        />
                        <p className='member-row4-item2'>{memberCount}/{sharingQuota}</p>
                    </div>


                    {this.state.list_member_array.length === 0? (
                        <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                            

                            <div className='member-row3'>
                                
                                <p className='add-member-caption'>No member added</p>
                            </div>
                        </div>
                    ) : null }

                    
                    <div className='user-list-container'>
                        {memberCount>0?member.map((item,index) => this.ListMemberComponentHandler(item,index)):null}
                    </div>


                </div>
            </div>
        )

        return render
    }

    ListComponentHandler = (item,index) => {
        let status = false
        var render = null
        try {
            status = item.status
        } catch (error) {
            
        }
        if (!status){
            render = (
                <div 
                    className='row-member-item cursor-class' 
                    key={index}
                    onClick={()=>{
                        
                        if(status === false){
                            this.setState({
                                choosenMember:item,
                                member_array:[]
                            })
                            document.getElementById('input-member').value = ""
                            addMember(item,this)
                            //document.getElementById('input-member').blur()
                        } else {
                            this.setState({
                                choosenMember:{
                                    Username:'',
                                    FirebaseID:'',
                                },

                            })
                        }
                    }}
                    >
                    <img alt="" className='row-member-item-avatar' src={imageHandler(item)}/>
                    <div className='row-member-item-col cursor-class'>
                        <p className='row-member-item-col1 cursor-class'>{stringHandler(item,'Name')}</p>
                        <p className='row-member-item-col2 cursor-class'>@{stringHandler(item,'Username')}</p>
                    </div>
                    <div 
                        // onClick={()=>{
                        //     status?console.log('this user has been invited'):addMember(item,this)
                        // }}
                        className='row-member-invite-btn-container'
                        >
                        {status?<button className='row-member-invite-btn' style={{backgroundColor:'#322f3f',color:'grey'}}>Added</button>:null}
                    </div>
                    
                </div>
            )
        }
        return render
    }

    ListMemberComponentHandler = (item,index) => {
        return (
            <div className='row-member-item' key={index}>
                <img alt="" className='row-member-item-avatar' src={imageHandler(item)} />
                <div className='row-member-item-col'>
                    <p className='row-member-item1-col1'>{stringHandler(item,'Name')}</p>
                    <p className='row-member-item1-col2'>@{stringHandler(item,'Username')}</p>
                </div>
                <div 
                    className='row-member-invite-btn-container'>

                    {item.role==='Admin'?(
                        <div 
                            className='row-member-admin'>
                            YOU
                        </div>
                    ):null}
                    {item.role==='Member'?(
                        <button 
                            onClick={()=>{
                                
                                deleteMember(item,index,context)
                            }}
                            className='row-member-remove-btn'>
                            Remove
                        </button>
                    ):null}
                    {/* <button className='row-member-invite-btn'>{item.role}</button> */}
                    
                </div>
                
            </div>
        )
    }

    DetailFeatureHandler = () => {
        var {totalQuota,usedQuota,numProject} = this.state
        return(
            <div className="personal-plans-detail">
                <div className="personal-plans-detail1">
                    <p className="personal-plans-detail1-lbl">Number of Projects</p>
                    <p className="personal-plans-detail1-val">{numProject}</p>
                </div>
                <div className="personal-plans-detail1">
                    <p className="personal-plans-detail1-lbl">Custom 3D Upload Quota</p>
                    <p className="personal-plans-detail1-val">{parseFloat(usedQuota/1000000).toFixed(2)}/{totalQuota/1000000} MB</p>
                    <div className="personal-plans-indicator">
                        <div style={{height:'100%',width:((usedQuota/1000000)/(totalQuota/1000000))*100+'%',borderRadius:20,backgroundColor:'#2A3884'}}/>
                    </div>         
                </div>    
            </div>
        )
    }

    PopupDialog = (context) => {
        var render = null
        var {cancelTemp,errorDialog,errorMessage} = context.state
        if(errorDialog && context.state.cancelTemp!==[]){
            render = (
                <div className='popup-dialog'
                    onClick={()=>{
                        
                        context.setState({
                            errorDialog:false
                        })
                    }}
                    >
                    <div className='cancel-dialog'>
                      <h3 className="cancel-label">Cancel Subscription</h3>
                      <p className="cancel-caption">{errorMessage}</p>
                      <div className='cancel-btn-container'>
                        <button 
                            onClick={()=>this.setState({errorDialog:false})}
                            className="cancel-btn1">
                            Abort
                        </button>
                        <button 
                            onClick={()=>cancelSubscription(context,cancelTemp[0]['invoice'])}
                            className="cancel-btn2">
                            Confirm
                        </button>
                      </div>
                    </div>

                    
                </div>
            )
        } 

        return render

    }

    MarkerDialog = () => {
        var render = null
        const {markerVisibility,markerQty} = this.state
        const context = this
        if(markerVisibility){
            render = (
                <div className='popup-overlay'>
                    <div className='dialog-container'>
                        <div className='dialog-header'>
                            <img 
                                alt=""
                                onClick={()=>{
                                    
                                    this.setState({
                                        markerVisibility:false
                                    })
                                }}
                                className='marker-exit' src='https://uploads-ssl.webflow.com/5daa78035197cf51c1bfb151/5e420ffb1674159cc51e1a68_close%20modal.svg'/>
                            Purchase Marker
                        </div>

                        <div className='marker-item1'>
                            Marker Amount
                            <input 
                                type='number' 
                                defaultValue={0}
                                className='marker-input1'
                                onChange={(e)=>{
                                        if(e.target.value >= 0){
                                            context.setState({
                                                markerQty:e.target.value
                                            })
                                        } else {
                                            e.preventDefault()
                                        }
                                    }
                                }
                            />
                        </div>

                        {/* <div className='marker-item2'>
                            Duration
                            <select className='marker-input2'>
                                <option value="1">1 month</option>
                                <option value="2">2 month</option>
                                <option value="3">3 month</option>
                                <option value="6">6 month</option>
                                <option value="12">12 month</option>
                            </select>
                        </div> */}

                        <div className='dialog-footer'>
                            <div className='marker-detail'>
                                <div className='marker-lbl1'>
                                    Total
                                </div>
                            <strong className='marker-val1'>USD {3*markerQty}</strong>
                            </div>
                            <div 
                                onClick={()=>{
                                    buyMarker(context, markerQty)
                                }}
                                className='marker-btn1 cursor-class'>
                                <strong>Purchase</strong>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return render
    }

    render(){
        var {plans,linked_plans,numProject,quotaCaption,pendingArray, markerUsed, userTotalQuota, userTotalUsedQuota, userTotalMarker} = this.state
        var plansCounter = 0
        plans.forEach(item => {
            if(item.plans_type !== 'Miscellaneous'){
                plansCounter++
            }
        })
        return(
            <div className="overview-parent">

                {pendingArray.length > 0?(
                    <div className="section"> 
                        <div className="w-container">
                            <div className='notification'>
                                <div className='notif-1'>Kamu belum menyelesaikan pembayaran untuk <strong className='bold-text-4'>{pendingArray[0]['plans'].includes('PLAN')?'EDUCATION: '+pendingArray[0]['plans']:pendingArray[0]['plans']+' PLAN'}</strong> yang kamu pilih beberapa waktu lalu</div>
                                <div onClick={()=>this.props.history.push('/Invoices')} className='notif-2 cursor-class'><strong>Lanjutkan Pembayaran</strong></div>
                            </div>
                        </div>
                    </div>  
                ):null}

                {/* ACCOUNT OVERVIEW */}
                <div className="section">
                    <div className="wider-container">
                    </div>
                    <div className="w-container">
                        <h6 className="section-overview">
                            Account Overview</h6>
                        <div className="account-overview-wrap">
                            <div className="account-overview first">
                                <h5 className="account-overview-title">
                                    Number of Projects</h5>
                                <div className="account-overview-value">
                                    <div className="value-text-block">
                                        {numProject}
                                    </div>
                                </div>
                            </div>
                            <div className="account-overview">
                                <h5 className="account-overview-title">
                                    Custom Objects</h5>
                                <div className="account-overview-value">
                                    <div className="value-text-block">
                                        {parseFloat(userTotalUsedQuota/1000000).toFixed(2)}</div>
                                    <div className="value-text-block garing">
                                        /</div>
                                    <div className="value-text-block limit">
                                        {userTotalQuota/1000000}</div>
                                    <div className="value-text-block mb">
                                        MB</div>
                                </div>
                                <p className="account-overview-extras">
                                    {quotaCaption}</p>
                            </div>
                            <div className="account-overview last">
                                <h5 className="account-overview-title">
                                    Custom Markers</h5>
                                <div className="account-overview-value">
                                    <div className="value-text-block">
                                        {markerUsed}</div>
                                    <div className="value-text-block garing">
                                        /</div>
                                    <div className="value-text-block limit">
                                    {userTotalMarker}
                                    </div>
                                </div>
                                <div 
                                    // href="# " 
                                    onClick={()=>{
                                        
                                        // this.setState({
                                        //     markerVisibility:true
                                        // })
                                        context.props.history.push('/Marker')
                                    }}
                                    
                                    className="add-marker-btn w-inline-block">
                                    <div>
                                        Buy Marker</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* USER SUBSCRIPTION */}
                <div className="section">
                    <div className="w-container">
                        <h6 className="section-overview">
                            Your Subscriptions
                        </h6>

                        {plansCounter>0?(
                            <div className="purchased-plans">
                                {plans.map(this.AvailablePlansContainer)}
                            </div>
                        ):null}
                            
                    </div>
                </div>
                

                {/* EMPTY SUBSCRIPTION */}
                {plansCounter === 0?(
                    <div className="section" style={{marginTop:-40}}>
                        <div className="w-container">
                            <div className="purchased-plans">
                                <div className="purchased-plan no-plan">
                                    <div className="card pp no-plan">
                                        <div className="text-block-2">
                                            You don’t have any<br/>
                                            active subscription,<br/>
                                            <div 
                                                // href="# "
                                                onClick={()=>{
                                                    this.props.history.push('/Plans')
                                                }}>
                                                Go choose one!
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ):null}
                
                {/* LINKED SUBSCRIPTION */}
                {linked_plans.length>0?(
                    <div className="section">
                        <div className="w-container">
                            <h6 className="section-overview">
                                You've Been Included InTo These Plans</h6>
                            <div className="purchased-plans">
                                {linked_plans.map(this.LinkedPlansContainer)}
                            </div>
                        </div>
                    </div>
                ):null}
                
                
                {/* {this.EducationMemberContainer()}
                {this.EducationListMemberContainer()}  */}
                {this.MemberDialog()}
                {this.state.loading? <div className="loader"/>:null}

                <Feedback/>
                {this.PopupDialog(this)}
                {this.MarkerDialog()}
                

                <div style={{height:60}}/>
            </div>
        )
    }

}

function mapDispatchToProps(dispatch){
    return {
        handleLogin : (data) => dispatch({type:'LOGIN_USER',data:data}),
        updateAccount : (data) => dispatch({type:'UPDATE_ACCOUNT',data:data}),
        handleLogout : () => dispatch({type:'LOGOUT_USER'}),
    }
}
const mapStateToProps = (state) => {
    return {
        redux : state
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Overview))


