import Axios from "axios"
import API_URL from "../../Config/Api"
import { getFirebaseToken } from "../../Query/FirebaseAuth"

function codeHandler (text,context) {
    context.setState({
        redeemCode : text
    })
}

async function codeChecker (context) {
    var {redeemCode } = context.state

    context.setState({
        loading:true
    })

    const token = await getFirebaseToken()

    Axios.post(API_URL+'/plans/voucher/free/',{
        "firebase_token":token,
        "code":redeemCode
    }).then(res => {
        console.log(res)
        
    }).catch(err => {
        console.log(err)
    })

    setTimeout(function(){
        if(redeemCode === ''){
            context.setState({
                activePage:2,
            })
        } else if(redeemCode === false){
            context.setState({
                activePage:3,
                loading:false,
            })
        } else if(redeemCode === false){
            context.setState({
                activePage:4,
                loading:false,
            })
        } else {
            context.setState({
                activePage:2,
            })
        }
    },1000)
}

export {
    codeHandler,
    codeChecker
}