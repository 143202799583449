import React from 'react'
import './index.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchUserFeatures} from '../../Query/Account'
// import { buyMarker } from '../Plans/action'
class CustomMarker extends React.Component{
    
    constructor(props){
        super(props)
        this.state = {
            markerCounter:1,
            markerCount:0,
            loading:false

        }
    }

    componentDidMount(){
        fetchUserFeatures(this)
        
    }

    render(){
        var selector = [2,3,4,5,6,7,8,9,10]
        const {markerCounter,markerCount,loading} = this.state
        const context = this
        return (
            <div className='cm-page'>
                <div className='cm-container'>
                    <div className='header'>If you want to augment an AR content to any visuals other than the default QR marker, you can use a custom image to be registered as AR marker</div>
                    <div className='form'>
                        <div className='f1'>
                            <div>You have:</div>
                            <div>{markerCount}</div>
                            <div>Custom AR markers</div>
                        </div>

                        <div> 
                            <div>How many custom markers do you need:</div>
                            <div className='cm-dropdown'>
                                <input 
                                    onChange={(e)=>{
                                        context.setState({markerCounter:e.target.value})
                                    }}
                                    id='cm-count' type='number' defaultValue={1}></input>
                                <select 
                                    onChange={(e)=> {
                                        document.getElementById('cm-count').value = e.target.value
                                        context.setState({markerCounter:e.target.value})
                                    }}>
                                    <option>1</option>
                                    {selector.map(element => {
                                        return <option label={element} value={element}/>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <button 
                        onClick={()=>{
                            context.setState({
                                loading:true
                            })

                            //buyMarker(context,markerCounter)
                            context.props.history.push('/Checkout/20?quantity='+markerCounter)
                        }}
                        className='cm-pay-btn'> 
                        Pay ${3*markerCounter}
                    </button>
                </div>
                
                {loading? <div className='screen-blocker'>
                    <div className="loader"/>
                </div>:null}
            </div>
        )
    }
}


function mapDispatchToProps(dispatch){
    return {
        handleLogout : () => dispatch({type:'LOGOUT_USER'}),
        updateToken : (data) => dispatch({type:'UPDATE_TOKEN',data:data})
    }
}
const mapStateToProps = (state) => {
    return {
        redux : state
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(CustomMarker))