import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import './index.css'
import { getInvoiceList } from '../../Query/Account'
import moment from 'moment'
import { showDetailInvoice, payInvoice } from './action'
import { postEvent3 } from '../../Function/GoogleAnalitycs'
import Feedback from '../../Function/Feedback'
import MountingComponent from '../../Function/MountingComponent'
import ReactToPdf from 'react-to-pdf'
import Midtrans from '../../Config/Midtrans';
import { loadStripe } from '@stripe/stripe-js'
const pdfComponent = React.createRef();


 

class Invoices extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            invoice_array : [],
            invoicePopUp:false,
            invoiceIndex:0,
            amount:0,
            loading:false,
            componentStatus:true,
        }
        var width = window.innerWidth
        var height = window.innerHeight

        const pdfOptionLandscape = {
            orientation: 'landscape',
            unit: 'in',
            format: [1300,490]
        };
        const pdfOptionPotrait = {
            orientation: 'potrait',
            unit: 'in',
            format: [850,975]
        }
        if(width>height){
            this.pdfLayout = pdfOptionLandscape
        } else {
            this.pdfLayout = pdfOptionPotrait
        }
        
    }

    componentDidMount(){
        getInvoiceList(this)
        postEvent3('Open Page','Invoice Page','')
        MountingComponent(true,this)

        window.addEventListener('resize',this.handleResize)
        if(!window.snap){
            console.log('ini stripe')
            loadStripe(process.env.REACT_APP_STRIPE_TOKEN);
        }
    }

    componentWillUnmount(){
        MountingComponent(false,this)
    }

    ListComponentHandler = (item,index,last) => {
        var {invoice_array} = this.state
        let render = null
        var amount = 0
        if(item.amount > 0 && item.amount !== null){
            amount = item.amount
        }

        render = (
            <div className="invoices-item-container" 
                key={index}
                style={index===last?{borderBottomWidth:0}:null}>
                <div className="invoices-item-label1">{moment(item.created).format('D MMM YYYY')}</div>
                <div className="invoices-item-label2">{item.plans.includes('PLAN')?'EDUCATION: '+item.plans:item.plans+" PLAN"} </div>
                <div className="invoices-item-label3">USD {String(amount).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}</div>
                <div className="invoices-item-label4">{item.Status===1?'Success':'Pending'}</div>
                
                

                {item.Status===0?(
                    <div className="invoices-item-btn-container">
                        <button
                            onClick={()=>{
                                postEvent3('Invoice Event','Open Invoice Detail',invoice_array[index].invoice_id)
                                showDetailInvoice(index,this,'Pending')
                            }}
                            className="invoices-item-btn1">View
                        </button>
                        <button 
                            onClick={()=>{
                                postEvent3('Invoice Event','Pay Invoice',invoice_array[index].invoice_id)
                                payInvoice(item)
                            }}
                            className="invoices-item-btn2">Pay</button>
                    </div>
                ):(
                    <div className="invoices-item-btn-container">
                        <button 
                            onClick={()=>{
                                postEvent3('Invoice Event','Open Invoice Detail',invoice_array[index].invoice_id)
                                showDetailInvoice(index,this,'Paid')
                            }}
                            className="invoices-item-btn3">View Receipt</button>
                    </div>
                )}
            </div>
        )
        return render
    }

    handleResize = () => {
        var width = window.innerWidth
        var height = window.innerHeight

        const pdfOptionLandscape = {
            orientation: 'landscape',
            unit: 'in',
            format: [1300,600]
        };
        const pdfOptionPotrait = {
            orientation: 'potrait',
            unit: 'in',
            format: [600,1300]
        }
        if(width>height){
            this.pdfLayout = pdfOptionLandscape
        } else {
            this.pdfLayout = pdfOptionPotrait
        }
    }

    pdfHandler = () => {
        
    }

    InvoiceDialog = () => {
        var {invoiceIndex,invoice_array} = this.state
        var invoice_id = ''
        var created = ''
        var amount = 0
        var plans = ''
        var expireDate = ''
        try {
            invoice_id = invoice_array[invoiceIndex].invoice_id
            created = invoice_array[invoiceIndex].created
        
            plans = invoice_array[invoiceIndex].plans
            expireDate = new Date(invoice_array[invoiceIndex].created);
            expireDate.setDate(expireDate.getDate() + 31)

            if(invoice_array[invoiceIndex].amount !== null && invoice_array[invoiceIndex].amount > 0){
                amount = invoice_array[invoiceIndex].amount
            }
        } catch (error) {
            
        }

        var render = null 

        if(this.state.invoicePopUp){
            render = (
                <div className='popup-overlay'>
                    <div 
                        ref={pdfComponent}
                        className='dialog-container invoice-dialog'>
                        <div className='dialog-header'>
                            <img 
                                alt=""
                                onClick={()=>{
                                    
                                    this.setState({
                                        invoicePopUp:false
                                    })
                                }}
                                className='marker-exit' 
                                src='https://uploads-ssl.webflow.com/5daa78035197cf51c1bfb151/5e420ffb1674159cc51e1a68_close%20modal.svg'
                            />
                            Invoice Detail
                        </div>
                        
                        <div className='invoice-content'>
                            <div className='invoice-content1-1'>
                                <div className='invoice-content1-1-1'>Invoice</div>
                                <div className="invoice-content1-1-2">
                                    <p className="modal-invoice-header-item1-spec1-label">ID</p>
                                    <p className="modal-invoice-header-item1-spec1-val">{invoice_id}</p>
                                </div>
                                <div className="invoice-content1-1-2">
                                    <p className="modal-invoice-header-item1-spec2-label">DUE DATE</p>
                                    <p className="modal-invoice-header-item1-spec1-val">{moment(created).format('DD MMM YYYY')}</p>
                                </div>
                            </div>

                            <div className='invoice-content1-2'>
                                <div className='invoice-content1-1-1'>From</div>
                                <div className="modal-invoice-header-item1-spec">
                                    <p className="modal-invoice-header-item2-spec1-val">P.T. Assemblr Teknologi Indonesia</p>
                                </div>
                                <div className="modal-invoice-header-item1-spec">
                                    <p className="modal-invoice-header-item2-spec2-val">Jl. Ir. H. Juanda no. 477A Block D Bandung, West Java. Indonesia +62 (22) 2046 3080</p>
                                </div>
                            </div>

                            <div className='invoice-content1-2'>
                                <div className='invoice-content1-1-1'>For</div>
                                <div className="modal-invoice-header-item1-spec">
                                    <p className="modal-invoice-header-item2-spec1-val">{this.props.redux.AccountData.Name}</p>
                                </div>
                                <div className="modal-invoice-header-item1-spec">
                                    <p className="modal-invoice-header-item2-spec2-val">{this.props.redux.AccountData.Email}</p>
                                </div>
                            </div>
                            
                        </div>
                    
                        <div className='invoice-content'>
                            <div className='invoice-content2-1'>
                                <div className='invoice-content2-1-1'>Description</div>
                                <div className="modal-invoice-header-item1-spec">
                                    <p className="modal-invoice-header-item2-spec1-val">{plans} Subscription</p>
                                </div>
                                
                            </div>
                            <div className='invoice-content1-1'>
                                <div className='invoice-content2-2-1'>Price</div>
                                <div className="modal-invoice-header-item1-spec">
                                    <p className="modal-invoice-header-item2-spec1-val invoice-detail">USD {String(amount).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</p>
                                </div>
                                
                            </div>
                            <div className='invoice-content1-1'>
                                <div className='invoice-content2-2-1'>Quantity</div>
                                <div className="modal-invoice-header-item1-spec">
                                    <p className="modal-invoice-header-item2-spec1-val invoice-detail">1</p>
                                </div>
                                
                            </div>
                            <div className='invoice-content1-1'>
                                <div className='invoice-content2-2-1'>Amount</div>
                                <div className="modal-invoice-header-item1-spec">
                                    <p className="modal-invoice-header-item2-spec1-val invoice-detail">USD {String(amount).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</p>
                                </div>
                                
                            </div>
                            
                        </div>
                        
                        
                        <div className='invoice-footer'>
                            <ReactToPdf targetRef={pdfComponent} options={this.pdfLayout} filename={invoice_id+".pdf"}>
                                {({toPdf}) => (
                                    <button className='invoice-btn' onClick={toPdf}>Download PDF</button>
                                )}
                            </ReactToPdf>
                            {this.state.status!=='Paid'?(<div 
                                onClick={()=>{
                                    
                                    postEvent3('Invoice Event','Pay Invoice',invoice_array[invoiceIndex].invoice_id)
                                    payInvoice(invoice_array[invoiceIndex])
                                    this.setState({
                                        invoicePopUp:false,
                                        loading:true
                                    })
                                }}
                                className='invoice-btn'>
                                Pay
                            </div>):null}
                        </div>
                        
                    </div>
                </div>
            )
        }

        return render
    }
    
    render(){
        const counter = this.state.invoice_array.length-1
        return(
            <div className="invoices-parent">
                <Midtrans/>
                <h1 className="centered-text">Invoices</h1>

                <div className="invoices-list-container">
                    {!this.state.loading?this.state.invoice_array.map((item,index) => this.ListComponentHandler(item,index,counter)):null}
                    {this.state.invoice_array.length === 1 &&this.state.loading?(
                        <div className="invoices-item-container-empty">
                            <p className="invoices-item-label-empty">No Transaction Found</p>
                        </div>
                    ):null}
                    {this.state.invoice_array.length === 0? (
                        <div className="invoices-item-container-empty">
                            <p className="invoices-item-label-empty">No Transaction Found</p>
                        </div>
                    ):null}
                </div>
                <Feedback/>
                {/* {this.InvoiceDetail()} */}
                {this.InvoiceDialog()}
            
            </div>
        )
    }

}

function mapDispatchToProps(dispatch){
    return {
        handleLogout : () => dispatch({type:'LOGOUT_USER'}),
    }
}
const mapStateToProps = (state) => {
    return {
        redux : state
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Invoices))