import firebase from 'firebase/app'
import { postEvent3 } from '../../Function/GoogleAnalitycs'
import Resizer from 'react-image-file-resizer'
import axios from 'axios'

function handleInput(context,type,event){
    var text = event.target.value
    if(type === 'Username'){
        context.Username = text.toLowerCase()
        context.UsernameChanged = true
    } else if(type === 'Name'){
        context.Name = text
    } else if( type === 'Bio'){
        context.Bio = text
    } else if(type === 'oldPassword'){
        context.oldPassword = text
    } else if(type === 'newPassword'){
        context.newPassword = text
    } else if(type === 'newConfirmPassword'){
        context.newConfirmPassword = text
    }
}

function usernameValidator(e,param){
    if(param === 'Username'){
        const regex = RegExp('[0-9a-z]');

        if (!regex.test(e.key) && e.key !== 'backspace') {
            e.preventDefault();
            alert('only lowercase and 0-9a-z allowed')
        } 
    }
}

function updateAccountData(context){
    postEvent3('Account Event','Update Account Data','')
    const id = context.props.redux.AccountData.FirebaseID
    const {Name,Bio} = context

    

    if(context.UsernameChanged){
        const oldUsername = context.props.redux.AccountData.Username
        const FirebaseID = context.props.redux.AccountData.FirebaseID
        const newUsername = context.Username
        
        if(newUsername.length > 5){
            
            
            firebase.database().ref('UserProfile').child(id).update({
                Name:Name,
                Bio:Bio,
            })

            firebase.database().ref('UsernameData').child(newUsername).once('value',snap => {
                if(snap.val() === null){
                    if(oldUsername !== null && oldUsername !== undefined){
                        firebase.database().ref('UsernameData').child(oldUsername).set(null)
                    }
                    firebase.database().ref('UsernameData').child(newUsername).set(FirebaseID)
                    firebase.database().ref('UserProfile').child(id).update({
                        Username:newUsername,
                    })
                    context.props.history.push('/Overview')
                } else {
                    alert('Username is taken')
                }
            })

            
        } else {
            alert('username must be at least 5 characters long')
        }
    } else {
        firebase.database().ref('UserProfile').child(id).update({
            Name:Name,
            Bio:Bio
        })
        context.props.history.push('/Overview')
    }

    
}

function updatePassword(context){
    firebase.auth().signInWithEmailAndPassword(context.props.redux.AccountData.Email, context.oldPassword)
    .then((userCredential) => {
        userCredential.user.updatePassword(context.newPassword).then(function() {
            console.log('password updated')
            context.setState({
                changePasswordDialog:false
            })
        }).catch(function(error) {
            
        });
        

    })
}

async function uploading(formData) {
    const tok = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);
    const headers = {
        authorization: `Bearer ${tok}`
    };
    let baseuUrl = 'https://us-central1-assemblr-1ff3f.cloudfunctions.net/app/v2';
    const respun = await axios.post(`${baseuUrl}/auth/login`, null, {
        headers
    });
    const token = respun.data.results;
    const headerss = {
        authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
    };
    const respunUplud = await axios.post(
        `${baseuUrl}/accounts/profiles/upload`,
        formData,
        { headers: headerss }
    );
    return respunUplud.data;
}

async function uploadProfilePic(formData,context) {
    context.setState({loading:true})
    try {
      await uploading(formData);
      //return commit('uploadProfilePic', aha);
      context.setState({loading:false})
    } catch (e) {
      console.log(e);
      context.setState({loading:false})
      //dispatch('console/pushError', e, { root: true });
    }
  }


function fileHandler(file,context){

    const formData = new FormData();
    formData.append('file',file)

    var reader = new FileReader();
        
        
    reader.onload = () => {
        context.setState({profileURL:reader.result})
    }

    var blob = new Blob([file[0]], {type:file[0].type});

    if(file){
        Resizer.imageFileResizer(
            blob, //is the file of the new image that can now be uploaded...
            200, // is the maxWidth of the  new image
            200, // is the maxHeight of the  new image
            'jpg', // is the compressFormat of the  new image
            100, // is the quality of the  new image
            0, // is the rotatoion of the  new image
            res => {
                //console.log(file.name)
                res.name = file[0].name
                res.lastModified = file[0].lastModified
                res.lastModifiedDate = new Date()
                var compressedFile = new File([res],file[0].name)
                reader.readAsDataURL(compressedFile)
                let formData = new FormData();
                formData.append("file", compressedFile);
                uploadProfilePic(formData,context)
            },  // is the callBack function of the new image URI
            'blob'  // is the output type of the new image
        );

        
    } else {
        context.setState({componentStatus:true})
    }



}


export {
    handleInput,
    updateAccountData,
    fileHandler,
    updatePassword,usernameValidator
}