function showDetailInvoice(index,context,status){
    
    context.setState({
        invoicePopUp:true,
        invoiceIndex:index,
        status:status,
    })
}

function payInvoice(item){
    window.snap.pay(item.token)
}

export {
    showDetailInvoice,
    payInvoice
}