import React, { Component } from "react";
import firebase from "firebase/app";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

export class RequestToken extends Component {
  sendTokenToVuplex = () => {
    console.log("sending token to unity");
    firebase.auth().onAuthStateChanged(async function (user) {
      if (user === null) {
        window.vuplex.postMessage({
          message: "Firebase Auth Expired, Please login again",
        });
      } else {
        let token = await firebase.auth().currentUser.getIdToken();
        window.vuplex.postMessage({
          message: token,
        });
      }
    });
  };

  componentDidMount() {
    var token = firebase.auth();
    console.log({ token });

    if (window.vuplex) {
      this.sendTokenToVuplex();
    } else {
      // The window.vuplex object hasn't been initialized yet because the page is still
      // loading, so add an event listener to send the message once it's initialized.
      console.log("mana vuplexnya");
      window.addEventListener("vuplexready", this.sendTokenToVuplex);
    }
  }

  render() {
    return <div></div>;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleLogout: () => dispatch({ type: "LOGOUT_USER" }),
    updateToken: (data) => dispatch({ type: "UPDATE_TOKEN", data: data }),
  };
}
const mapStateToProps = (state) => {
  return {
    redux: state,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RequestToken));
