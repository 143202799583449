import React from 'react'
import './index.css'
import { withRouter } from 'react-router-dom'
import { LoadingBar } from '../../Function/LoadingBar'
import { codeHandler, codeChecker } from './action'


var context = null

class Redeem extends React.Component {


    constructor(props){
        super(props)
        this.state = {
            activePage : 1,
            loading:false,
            salePlan:['Personal Plan','Education Plan'],
            redeemCode :''
        }
        context = this
    }

    invalidComponent = () => {
        setTimeout(function(){
            context.setState({
                activePage:1,
                loading:false
            })
        },3000)
        return (
            <div className='redeem-component'>
                <img alt='' className='ic-invalid' src={require('../../Assets/icon/ic_warning.png')}/>
                <div className='warning-lbl'>Invalid voucher code!!!</div>
            </div>
        )
    }

    redeemForm = () => {
        return (
            <div className='redeem-component'>
                <input 
                    onChange={(event)=>{codeHandler(event.target.value,this)}}
                    className='redeem-input1'
                    defaultValue=''
                    placeholder='Enter voucher code'
                />

                <button
                    onClick={()=>codeChecker(this)}
                    className='redeem-btn1'>
                    Validate
                </button>

                <button
                    onClick={()=>this.props.history.goBack()}
                    className='redeem-btn2'>
                    Cancel
                </button>

                
            </div>
        )
    }

    discountComponent = () => {
        var {salePlan} = this.state
        return (
            <div className='redeem-component'>
                <div className='discount-lbl1'>YAAY!!<br/> You got a discount code</div>
                <div className='discount-lbl2'>50% Off</div>
                <div className='discount-lbl1'>Can be used for</div>
                <div className='discount-grid'>
                    {salePlan.map((item)=>{
                        return (
                            <div 
                                onClick={()=>this.props.history.replace('/Plans')}
                                className='discount-item'>
                                {item}
                                <div className='discount-item-btn'>
                                    buy    
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    freeComponent = () => {
        return (
            <div className='redeem-component'>
                <div className='discount-lbl1'>YAAY!!<br/> You got plan </div>
                <div className='planCard'>
                    <div className='planCard-lbl1'>Education: Plan A</div>
                    <div className='planCard-lbl2'>1 Year</div>
                    <div 
                        onClick={()=>this.props.history.replace('/Plans')}
                        className='planCard-btn'>Activate Now </div>
                </div>

                
            </div>
        )
    }


    componentHandler = () => {
        var {activePage} = this.state
        switch(activePage) {
            case 1:
                return this.redeemForm()
            case 2:
                return this.invalidComponent()
            case 3 :
                return this.discountComponent()
            case 4 :
                return this.freeComponent()
            default:
                return this.redeemForm()
        }
    }

    render(){
        var {loading} = this.state
        return (
            <div className='parent-redeem'>
                {this.componentHandler()}
                {loading?<LoadingBar/>:null}
            </div>
        )
    }
}

export default withRouter(Redeem)