import React from 'react'
import './index.css'
const Feedback = () => {
    return (
        <div 
            onClick={()=>{window.open('https://assemblr-feedbacks.typeform.com/to/rjfLjU','_blank')}}
            className='floating-feed'>
            <img 
                alt=""
                className='feed-icon' 
                src={require('../../Assets/icon/feed-icon.svg')} 
            />
            <div className='feed-label'>Got Some feedback?</div>
        </div>
    )
}

export default Feedback