function showDialog(message,context){
    context.setState({
        loading:false,
        errorDialog:true,
        errorMessage:message
    })
    setTimeout(()=>{
        context.setState({
            errorDialog:false,
            errorMessage:""
        })
    },2000)
}

export {
    showDialog
}