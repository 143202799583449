import { authLogout } from "../../Query/FirebaseAuth"


async function logoutHandler(context){
    await authLogout(context)
    //context.props.handleLogout()
    //context.props.history.replace('/')
}

const navigatorHandler = (context, currentAddress) => {
    const path = context.props.location.pathname
    if(path === currentAddress){
        return {opacity:1}
    } else {
        return {opacity:0.5}
    }
}

export {
    logoutHandler,
    navigatorHandler
}