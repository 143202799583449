import ReactGA from 'react-ga';

function postEvent3(category,action,label){
    ReactGA.event({
        category: category,
        action:action,
        label:label
    });
}

function sendEvent(param){


    if(param.length === 1 ){
        //window.ga('send',param[0])
        ReactGA.event({
            category: param[0],
            action:'',
            label:''
        });
    } else if(param.length === 2){
        //window.ga('send',param[0],param[1])
        ReactGA.event({
            category: param[0],
            action:param[1],
        });
    } else if(param.length === 3){
        //window.ga('send',param[0],param[1],param[2])
        ReactGA.event({
            category: param[0],
            action:param[1],
            label:param[2]
        });
    } else if(param.length === 4){
        //window.ga('send',param[0],param[1],param[2])
        ReactGA.event({
            category: param[0],
            action:param[1],
            label:param[2],
            value:param[3],
        });
    }

    
    
}

export {
    postEvent3,
    sendEvent
}