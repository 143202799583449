import { checkUsername } from "../../Query/Account"
import { showDialog } from "../../Function/Dialog"
import { registerWithEmail } from "../../Query/FirebaseAuth"


function handleInput(context,type,text){
    if(type === "fullName"){
        context.fullName = text
    } else if(type === "Username"){
        if (/^[a-zA-Z0-9]+$/.test(text) || text === "") {
            context.Username = text.toLowerCase()
        }
        console.log(context.Username)
    } else if(type === "Email"){
        context.Email = text
    } else if(type === "Password"){
        context.Password = text
    } else if(type === "Password2"){
        context.Password2 = text
    } 
}

async function registerHandler(context){

    context.setState({
        loading : true
    })

    setTimeout(async function(){
        if(context.fullName === "" || context.Username === "" ||  context.Email === "" || context.Password === "" || context.Password2 === ""){
            showDialog('Please fill in all input field !!',context)
        } else if(context.state.termsAgreement === false){
            showDialog('Please check the agreement!', context)
        } else if(context.Password !== context.Password2){
            showDialog('Password not match',context)
        } else {
            const available = await checkUsername(context.Username)
            if(!available){
                const data = {
                    Email : context.Email,
                    Password : context.Password,
                    Name:context.fullName,
                    Username:context.Username
                }
                registerWithEmail(data,context)
    
            } else {
                showDialog('Username is taken or format not valid please insert lowercase only without space!',context)
            }
        }
    },1000)

    

    

}

export {
    handleInput,
    registerHandler
}