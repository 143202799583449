import React from 'react';
import './index.css'
import {
    Route,
} from "react-router-dom";
import {connect} from 'react-redux'
import {logoutHandler} from './action'
import {withRouter} from 'react-router-dom'
import Overview from '../Overview';
import Plans from '../Plans2';
import Invoices from '../Invoices';
import firebase from 'firebase/app'
import AccountSetting from '../AccountSetting';
import axios from 'axios'
import { directOrder } from '../Plans/action';
import CustomMarker from '../CustomMarker';
import Checkout from '../Checkout';

class Main extends React.Component{

    constructor(props){
        super(props)
        this.userID = props.redux.AccountData.FirebaseID
        this.state = {
            componentStatus:false,
            headerTooltip:false,
            navbarOpen:false,
            navbarVisibility:true
        }

        this.logoutHandler = logoutHandler
        //

       


        // if(this.userID === "" && ){
        //     props.history.replace('/Auth/Login')
        // }

        //props.history.replace('/Index/Overview')

    }

    componentDidMount(){
        const context = this
        const pathName = window.location.pathname
        firebase.auth().onAuthStateChanged(async function(user) {
            if (user === null) {
                console.log(pathName)
                if(pathName !== '/Plans'){
                    console.log('Firebase Auth Expired, Please login again')
                    context.props.handleLogout()
                    context.props.history.replace('/Auth/Login')
                }
            } else {
                var firebaseToken = ''
                try {
                    if(user.ma !== undefined && user.ma !== null && typeof(user.ma) === 'string'){
                        
                        firebaseToken = user.ma
                    }
                } catch (error) {
                    
                }
                try {
                    if(user.xa !== undefined && user.xa !== null && typeof(user.xa) === 'string'){
                        firebaseToken = user.xa
                    }
                } catch (error) {
                    
                }
                context.props.updateToken(firebaseToken)  
                console.log('token updated')
                if(global.directOrder === true){
                    console.log('redirecting to your order')
                    const token = firebaseToken
                    directOrder(token,global.directOrderId,context)
                }

                if(global.isAstu === true){
                    const tok = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);
                    const headers = {
                        authorization: `Bearer ${tok}`
                    };
                    const baseuUrl = 'https://us-central1-assemblr-1ff3f.cloudfunctions.net/app/v2'
                    const respun = await axios.post(`${baseuUrl}/auth/login`, null, {
                        headers
                    });
                    window.location = 'assemblrstudio://login?token='+respun.data.results
                }

                context.setState({navbarVisibility:true})
                if(pathName === '/'){
                    context.props.history.replace('/Overview')
                }
            }
        });

        
    }


    

    render(){
        var pathName = window.location.pathname
        const {navbarVisibility} = this.state
        return(
            <div className="parent-container">

                {navbarVisibility?(
                    <div className={this.state.navbarOpen?"navbar open":"navbar"}>
                        <div className="wider-container nav-bar">
                            <div 
                                // href="# " 
                                className="brand w-nav-brand">
                                <div className="brand-logo">
                                    Assemblr</div>
                                <div className="brand-sub">
                                    Account Dashboard
                                </div>
                            </div>
                            <div className="menus" onClick={()=>this.setState({
                                    navbarOpen:!this.state.navbarOpen
                                })}>
                                <div 
                                    // href="# "
                                    onClick={()=>this.props.history.push('/Overview')} 
                                    className={"menu cursor-class w-inline-block "+(pathName==="/Overview"?"active":"")}>
                                    <div>
                                        Overview</div>
                                </div>
                                <div  
                                    // href="# "
                                    onClick={()=>this.props.history.push("/Plans")}
                                    className={"menu cursor-class w-inline-block "+(pathName==="/Plans"?"active":"")}>
                                    <div>
                                        Upgrade Plan</div>
                                </div>
                                <div  
                                    // href="# "
                                    onClick={()=>this.props.history.push("/Marker")}
                                    className={"menu cursor-class w-inline-block "+(pathName==="/Marker"?"active":"")}>
                                    <div>
                                        Upgrade Marker</div>
                                </div>
                                <div  
                                    // href="# "
                                    onClick={()=>this.props.history.push("/Invoices")} 
                                    className={"menu cursor-class w-inline-block "+(pathName==="/Invoices"?"active":"")}>
                                    <div>
                                        Invoices</div>
                                </div>
                                <div className="div-block-2">
                                </div>
                                <div  
                                    // href="# "
                                    onClick={()=>this.props.history.push("/AccountSetting")} 
                                    className={"menu cursor-class w-inline-block "+(pathName==="/AccountSetting"?"active":"")}>
                                    <div>
                                        Account</div>
                                </div>
                                <div  
                                    // href="# "
                                    onClick={()=>this.logoutHandler(this)} className="menu cursor-class w-inline-block">
                                    <div>
                                        Logout</div>
                                </div>
                            </div>
                            <div 
                                onClick={()=>this.setState({
                                    navbarOpen:!this.state.navbarOpen
                                })}
                                className="menu-toggle">
                                <div className="toggle-a">
                                </div>
                                <div className="toggle-b">
                                </div>
                            </div>
                        </div>
                    </div>
                ):null}

                <Route path="/OverView">
                    <Overview />
                </Route>
                <Route path="/Plans">
                    <Plans parentContext={this}/>
                </Route>
                <Route path="/Invoices">
                    <Invoices/>
                </Route>
                <Route path="/AccountSetting">
                    <AccountSetting/>
                </Route>
                <Route path="/Marker">
                    <CustomMarker />
                </Route>

                <Route path="/Checkout/:id">
                    <Checkout />
                </Route>
                

                {/* {!navbarVisibility?(
                    <div className='navbarHidden'/>
                ):null} */}

                
            </div>
        )
    }


}

function mapDispatchToProps(dispatch){
    return {
        handleLogout : () => dispatch({type:'LOGOUT_USER'}),
        updateToken : (data) => dispatch({type:'UPDATE_TOKEN',data:data})
    }
}
const mapStateToProps = (state) => {
    return {
        redux : state
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Main))