import firebase from 'firebase/app'
import axios from 'axios'
import API_URL from '../Config/Api'


async function fetchSubscriptionPlans(context){
    let result = []
    await firebase.database().ref('SubscriptionPlans').once('value',snapshot=>{
        const snapData = snapshot.val()
        result = Object.values(snapData) 
    })
    return result
}

async function fetchUserSubscription(context){
    
    const {SubscriptionPlans} = context.props.redux

    let resultData = null
    
    const uid = context.props.redux.AccountData.FirebaseID
    
    await firebase.database().ref('UserSubscriptions').orderByChild('uid').equalTo(uid).once('value',snapshot=>{
        const snapData = snapshot.val()
        var data = []
        try {
            data = Object.values(snapData) 
        } catch (error) {
            data = snapData
        }
        resultData = data
    })
    resultData.forEach(val1 => {
        
        const extraData = SubscriptionPlans.filter(val2 => {
            return val2.id === val1.PlanId
        })
        val1.interval = extraData[0]['interval']
        val1.monthlyPrice = extraData[0]['monthlyPrice']
        val1.annuallyPrice = extraData[0]['annuallyPrice']
        
    })

    return resultData


}


async function getSubscriptionPlans(store){
    axios.get(API_URL+'/plans/personal').then(resp => {
        const data = resp.data.plans_personal
        data.sort((a,b)=>a.id - b.id)
        
        store.dispatch({type:'UPDATE_SUBSCRIPTION_PLAN_PERSONAL',data:data})
    })
    axios.get(API_URL+'/plans/education').then(async function(resp){
        var data = resp.data.plans_educations
        // data = [
        //     {"id":9,"plans_type":"Education","name":"Basic","price":"$300","purpose":"EDUCATION PLAN A ANNUALLY SUBSCRIPTION","country_code":"+62","pricing_code":"EDUCATION PLAN A","duration":365,"marker":25,"byte_size_total":2500000000,"byte_size_personal":100000000,"estimate":0,"exclusive_access":true,"total_users":25},
        //     {"id":10,"plans_type":"Education","name":"Premium","price":"$600","purpose":"EDUCATION PLAN B ANNUALLY SUBSCRIPTION","country_code":"+62","pricing_code":"EDUCATION PLAN B","duration":365,"marker":60,"byte_size_total":12000000000,"byte_size_personal":200000000,"estimate":0,"exclusive_access":true,"total_users":60},
        //     {"id":11,"plans_type":"Education","name":"Individual","price":"$5","purpose":"EDUCATION INDIVIDUAL MONTHLY SUBSCRIPTION","country_code":"+62","pricing_code":"EDUCATION PLAN B","duration":31,"marker":3,"byte_size_total":600000000,"byte_size_personal":200000000,"estimate":0,"exclusive_access":true,"total_users":3},
        // ]
        await data.forEach((item,index)=>{
            if(item.name==="Premium"){
                item.src = require('../Assets/icon/plan-b-icon.png')
                item.backgroundColor = '#D81A63'
                item.virtual_class = 'Unlimited'
                item.offline_access = true
                item.interactivity_builder = true
                item.web_embed = true
                item.addon_class_price = 0.8
                item.type = 1
            } else {
                item.src = require('../Assets/icon/plan-a-icon.png')
                item.backgroundColor = '#FAC02D'
                item.virtual_class = 'Unlimited'
                item.offline_access = false
                item.interactivity_builder = false
                item.web_embed = false
                item.addon_class_price = 1
            }
        })


        data.sort((a,b)=>a.id - b.id)
        
        store.dispatch({type:'UPDATE_SUBSCRIPTION_PLAN_EDUCATION',data:data})
    })
}

export {
    fetchSubscriptionPlans,
    fetchUserSubscription,
    getSubscriptionPlans
}