import { loginWithEmail } from "../../Query/FirebaseAuth"
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import { showDialog } from "../../Function/Dialog"
import {  checkUserData } from "../../Query/Account"
import Axios from 'axios'
// import { findAllByDisplayValue } from "@testing-library/react"
function handleInput(context,type,text){
    if(type === "Email"){
        context.Email = text
    } else if(type === "Password"){
        context.Password = text
    }
}

async function loginHandler(context){
    console.log("logging in")
    if(context.Email === "" || context.Password === "" ){
        context.setState({
            errorDialog:true,
            errorMessage:"Please fill all input form",
            loading:false,
        })
        // setTimeout(()=>{
        //     context.setState({
        //         errorDialog:false,
        //         errorMessage:""
        //     })
        // },2000)
        console.log('Please input all form')
    } else {
        context.setState({
            loading:true
        })
    
        setTimeout(async ()=>{
            if(context.Email.includes('@')){
                console.log('login with email')
            } else {
                console.log('login with username')
                const email = await new Promise(async resolve => {
                    var uid = await new Promise(async resolve1 => { 
                        await firebase.database().ref('UsernameData/'+context.Email).once('value',snap=>{
                            if(snap.val()){
                                resolve1(snap.val())
                            } else {
                                // alert('Username not available!')
                                context.setState({
                                    loading: false,
                                    errorDialog: true, 
                                    errorMessage:"Username not available!"
                                })
                            }
                        })
                    })
                    var emails = await new Promise(async resolve2 => {
                        await firebase.database().ref('UserProfile/'+uid).child('Email').once('value', snap => {
                            if(snap.val()){
                                resolve2(snap.val())
                            } else {
                                // alert('Username not available!')
                                context.setState({
                                    loading: false,
                                    errorDialog: true, 
                                    errorMessage:"Username not available!"
                                })
                            }
                        })
                    })
                    resolve(emails)
                    
                })
                context.Email = email
            }

            const userData ={
                Email:context.Email,
                Password:context.Password
            }
            loginWithEmail(userData,context)
        },500)
    }
    
}


function onLoginSuccess(context,userData,loginType){
    const data = {
        userData:userData,
        loginType:loginType,
    }
    //console.log(data)
    checkUserData(data.userData.FirebaseID,data.userData.Email)
    context.props.handleLogin(data)

    if(window.vuplex){
        firebase.auth().onAuthStateChanged(async function(user) {
            if(user){
                const tok = await user.getIdToken(/* forceRefresh */ true);
                const headers = {
                    authorization: `Bearer ${tok}`
                };
                const baseuUrl = 'https://us-central1-assemblr-1ff3f.cloudfunctions.net/app/v2'
                const respun = await Axios.post(`${baseuUrl}/auth/login`, null, {
                    headers
                });
                context.setState({
                    loading:false
                })
                if (window.vuplex) {
                    // The window.vuplex object already exists, so go ahead and send the message.
                    console.log('send message')
                    window.vuplex.postMessage({ token:respun.data.results, tok:tok });
                }
            }
        });
    } else {
        if(!navigator.userAgent.includes("Studio")) {
            context.props.history.replace('/Overview')
        }
        if(global.redirectPage){
            context.props.history.replace(global.redirectPage)
        }
    }

    
}

function onLoginFailed(context,message){
    showDialog(message,context)
}


function withFacebook() {
    return new Promise((resolve, reject) => {
        const provider = new firebase.auth.FacebookAuthProvider();

        /* POPUP LOGIN */
        firebase.auth()
            .signInWithPopup(provider)
            .then(function (result) {
                // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                // var token = result.credential.accessToken;
                // // The signed-in user info.
                // const user = result.user;
                
                
                
                return resolve(result);
            })
        .catch(reject);
    });
}

async function loginHandler2(context){
    const result = await withFacebook()
    const uid = result.user.uid
    const email = result.user.email
    const userData = {
        FirebaseID:uid,
        Email:email,
    }
    onLoginSuccess(context,userData,"Facebook")

}

async function loginHandler3(context){
    var provider = new firebase.auth.OAuthProvider('apple.com');
    firebase.auth()
            .signInWithPopup(provider)
            .then(async function(result) {
                var user = result.user;
                const uid = user.uid; 
                const email = result.user.email
                const userData = {
                    FirebaseID:uid,
                    Email:email,
                }
                onLoginSuccess(context,userData,"AppleID")  
    }).catch(err => {
        // alert(err.message)
        context.setState({
            loading: false,
            errorDialog: true, 
            errorMessage: err.message
        })
    })
}


async function authSignOut(context){
    await firebase.auth().signOut()
    context.props.handleLogout()
}

function signInFromEdu(context,token){
    console.log('login from token')
    firebase.auth().signInWithCredential(token).then(result=>{
        
        const uid = result.user.uid
        const email = result.user.email
        const userData = {
            FirebaseID:uid,
            Email:email,
        }
        onLoginSuccess(context,userData,"Email")
    }).catch(function(error) {
        // Handle Errors here.
        //var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorMessage)
        // ...
    });
}



export {
    loginHandler,
    loginHandler2,
    loginHandler3,
    onLoginSuccess,
    handleInput,
    withFacebook,
    onLoginFailed,
    authSignOut,
    signInFromEdu,
}