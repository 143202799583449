import React from "react";
import "./index.css";
import { connect } from "react-redux";
import {
  loginHandler,
  onLoginSuccess,
  handleInput,
  withFacebook,
  loginHandler2,
  loginHandler3,
  signInFromEdu,
} from "./action";
import { withRouter } from "react-router-dom";
import { getUrlParameter } from "../../Function/GetParameter";
import { LoadingBar } from "../../Function/LoadingBar";
import { authLogout } from "../../Query/FirebaseAuth";
import { Alert } from "blr-ui";
import 'blr-ui/dist/index.css'

let context = null;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.redux,
      loading: true,
      errorDialog: false,
      errorMessage: "Please fill in all form",
    };

    this.Email = "";
    this.Password = "";

    // BINDING ACTION JS TO THIS CLASS
    this.withFacebook = withFacebook;
    this.loginHandler = loginHandler;
    this.loginHandler2 = loginHandler2;
    this.loginHandler3 = loginHandler3;
    this.onLoginSuccess = onLoginSuccess;
    this.handleInput = handleInput;
  }

  componentDidMount() {
    context = this;
    setTimeout(() => {
      context.setState({
        loading: false,
      });
    }, 1000);
    const pathname = this.props.location.pathname;

    if (pathname === "/Auth/Logout") {
      authLogout(this);
    }

    var plans_id = getUrlParameter("plans_id");
    var token = getUrlParameter("token");
    if (token.length > 0) {
      signInFromEdu(this, token);
    }

    if (plans_id > 0) {
      console.log("direct order");
      global.directOrder = true;
      global.directOrderId = plans_id;
    } else {
      global.directOrder = false;
      global.directOrderId = 0;
    }

    const device = getUrlParameter("device");
    const provider = getUrlParameter("provider");
    if (device === "Studio" || provider !== "") {
      console.log("login from aassemblr studio");
      global.isAstu = true;
      if (this.state.isLoggedIn === true) {
        this.props.history.replace("/Redirect");
      }
    } else {
      console.log("login for web");
      global.isAstu = false;
      if (
        this.state.isLoggedIn === true &&
        this.props.redux.AccountData.FirebaseID !== "" &&
        this.props.redux.AccountData.FirebaseID !== undefined
      ) {
        if(window.vuplex === undefined){
          this.props.history.replace("/Overview");
        }
       
      }
    }
  }

  render() {
    return (
      <div className="login-parent-container">
        <img
          className="login-logo"
          alt="assemblr-logo"
          src={require("../../Assets/icon/assemblr_logo.svg")}
        />

        <div className="login-form-container">
          <p className="login-input-label">Email or Username</p>
          <div className="username-input-container">
            <input
              className="login-input-container"
              placeholder="Email or Username"
              onChange={(event) =>
                handleInput(this, "Email", event.target.value)
              }
            />
          </div>

          <div className="password-label-container">
            <p className="login-input-label">Password</p>
            <p
              onClick={() => this.props.history.push("/Auth/Reset")}
              className="reset-label"
            >
              Reset here
            </p>
          </div>

          <div className="username-input-container">
            <input
              type="password"
              className="login-input-container"
              placeholder="Password"
              onChange={(event) =>
                handleInput(this, "Password", event.target.value)
              }
            />
          </div>

          <button onClick={() => loginHandler(this)} className="login-button1">
            Login
          </button>
          <p className="or">or</p>
          <div className="auth-btn-container">
            <button
              onClick={() => this.loginHandler2(this)}
              className="login-button2"
            >
              Facebook
            </button>
            <button
              onClick={() => this.loginHandler3(this)}
              className="login-button2"
            >
              Apple ID
            </button>
          </div>
        </div>

        <p className="login-caption">Don't have account yet?</p>
        <p
          onClick={() => this.props.history.push("/Auth/Register")}
          className="link-register cursor-class"
        >
          Register here
        </p>

        {this.state.loading ? <LoadingBar /> : null}
        {/* {this.state.errorDialog? <div className="error-dialog">{this.state.errorMessage}</div>:null}  */}
        {this.state.errorDialog ? (
          <Alert
            visibility={this.state.errorDialog}
            context={this}
            hideAlert={() => this.setState({ errorDialog: false })}
            label={this.state.errorMessage}
            buttonText="OK"
            loading={null}
          />
        ) : null}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleLogin: (data) => dispatch({ type: "LOGIN_USER", data: data }),
    handleLogout: () => dispatch({ type: "LOGOUT_USER" }),
  };
}
const mapStateToProps = (state) => {
  return {
    redux: state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
