// import Axios from 'axios'
import firebase from 'firebase/app'
import {showDialog} from '../Function/Dialog'

async function loginWithEmail(data,context){
    firebase.auth().signInWithEmailAndPassword(data.Email, data.Password)
    .then(() => {
        console.log("welcome "+firebase.auth().currentUser.email+ " login success")
        const userData = {
            FirebaseID:firebase.auth().currentUser.uid,
            Email:data.Email,
        }
        
        context.onLoginSuccess(context,userData,"Email")

    })
    .catch((error) => {
        const { message } = error;
        context.setState({
            loading:false,
            errorDialog:true,
            errorMessage:message
        })
        setTimeout(()=>{
            context.setState({
                errorDialog:false,
                errorMessage:""
            })
        },2000)
        //context.onLoginFailed(message)
    })
}


async function registerWithEmail(data,context){
    var error = false
    firebase.auth().createUserWithEmailAndPassword(data.Email,data.Password)
      .catch((error) => {
        const { message } = error;
        showDialog(message,context)
    }).catch(err => {
        error = true
    })
    firebase.auth().onAuthStateChanged(async user => {
        if (user != null) {
            context.setState({
                loading:false
            })
            if(!error ){

                var isUserRegistered = await new Promise(resolve => {
                    firebase.database().ref('UserProfile/'+user.uid).once('value', snap => {
                        if(snap.val()){
                            resolve(true)
                        } else {
                            resolve(false)
                        }
                    })
                })

                if(!isUserRegistered){
                    firebase.database().ref('UserProfile/'+user.uid).set({
                        Name:data.Name,
                        Username:data.Username,
                        FirebaseID:user.uid,
                        Email:data.Email,
                        Bio:'',
                        Birthdate:'',
                        Coin:0,
                        Diamond:0,
                        AgreementPrivacyPolicy:true,
                        ProfilePictureURL:'https://assemblr-static.sfo2.digitaloceanspaces.com/images/defaultProfilePicture.jpg',
                    });
                    firebase.database().ref('UsernameData').child(data.Username).set(user.uid);
                    
                    const userData = {
                        FirebaseID:user.uid,
                        Email:data.Email,
                    }
                    context.onLoginSuccess(context,userData,"Email")
                } else {
                    
                }
            } 
        } else {
            if(user !== null){
                alert('Email already registered')
            }
        }
    });
}

function setAccountData(data,context){
    firebase.database().ref('UserProfile/'+data.UID).set({
        Name:data.Name,
        Username:data.Username,
        FirebaseID:data.UID,
        Email:data.Email,
        Bio:'',
        Birthdate:'',
        Coin:0,
        Diamond:0,
        AgreementPrivacyPolicy:true,
        ProfilePictureURL:'https://assemblr-static.sfo2.digitaloceanspaces.com/images/defaultProfilePicture.jpg',
    });
    firebase.database().ref('UsernameData').child(data.Username).set(data.UID);
    context.props.history.replace('/Profile')
}

async function authLogout(context){
    await firebase.auth().signOut().then(()=>{

        context.props.handleLogout()
        if(window.vuplex){
            console.log('logout for vuplex')
            window.vuplex.postMessage({ message:'logout' });


            window.vuplex.addEventListener('message', function(event) {
                let json = event.data;
                // > JSON received: { "type": "greeting", "message": "Hello from C#!" }
                if(json){
                    var timeout = setTimeout(function(){
                        context.props.history.replace('/Auth/Login')
                        clearTimeout(timeout)
                    },1000)
                }


            });
        }  else {
            context.props.history.replace('/Auth/Login')
        }
        console.log('logout success')
        

       
        

        
    })

}


const getFirebaseToken = async () => {
    return await new Promise(async resolve => {
        await firebase.auth().onAuthStateChanged(async user => {
            if(user){
                var res = await user.getIdToken(/* forceRefresh */ true);
                resolve(res)
            } else {
                resolve('')
            }
        })
    })
}



export {
    loginWithEmail,
    registerWithEmail,
    authLogout,
    setAccountData,
    getFirebaseToken
}