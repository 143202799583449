import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import axios from 'axios'
import { postEvent3 } from '../../Function/GoogleAnalitycs';
import API_URL from '../../Config/Api';
import { getFirebaseToken } from '../../Query/FirebaseAuth';


async function searchUsername(username,context){
    
    if(username === ""){
        context.setState({
            member_array:[],
        })
        
    } else {
        
        const uids = await searchProfile(username,10) 
        const profiles = uids.map(fetchUserProfile);
        const results = Promise.all(profiles);
        results.then(users => {
            let data = users;
            var array2 = data
            var array1 = context.state.plans[context.state.activeEduPlan].member
            try {
              array2.forEach(function (item,index) {
                item.status = false
                array1.forEach(function (childItem){
                    if(item.Name === "" || item.Name === undefined){
                      item.Name = 'Unknown'
                    }
                    if(item.FirebaseID === childItem.FirebaseID){
                      //array2.splice(index,1)
                      item.status = true
                    }
                    
                })
              });
            } catch (error) {
              context.setState({
                member_array:[]
              })
            }
            context.setState({
              member_array:array2
            })
        });
    }
        
}

const searchProfile = async (queryText, limit) => {
    const query = queryText.toLowerCase();
    return new Promise((resolve, reject) => {
        firebase.database().ref('UsernameData')
        .orderByKey()
        .startAt(query)
        .endAt(query + '\uf8ff')
        .limitToFirst(limit)
        .once('value')
        .then(snap => {
          let val = snap.val();
          let data = [];
          if (val && val !== undefined && typeof val === 'object') {
            Object.keys(val).forEach(key => {
              data.push(val[key]);
            });
          }
          resolve(data);
        })
        .catch(e => reject(e));
    });
};

const fetchUserProfile = async uid => {
  return new Promise((resolve, reject) => {
    if (!uid) return;
  
  firebase.database().ref('UserProfile/' + uid)
    .once('value')
    .then(snap => resolve(snap.val()))
    .catch(e => reject(e));
  });
};

const imageHandler = (item) => {
  try {
    if(item.ProfilePictureURL !== ''){
      return item.ProfilePictureURL
    } else {
      return 'https://assemblr-static.sfo2.digitaloceanspaces.com/images/defaultProfilePicture.jpg'
    }
  } catch (error) {
      return 'https://assemblr-static.sfo2.digitaloceanspaces.com/images/defaultProfilePicture.jpg'
  }
}

const stringHandler = (item,type) => {
  if(type === 'Username'){
    try {
      return item.Username
    } catch (error) {
      return 'Unamed'
    }
  } else if(type === 'Name'){
    try {
      return item.Name
    } catch (error) {
      return 'Unamed'
    }
  }
}

function renewSubscription(item,token){
  const id = item.id_plans
  postEvent3('Subscription Event','Renew plan',id)
  axios.post(API_URL+'/plans/request', {
    "firebase_token": token,
    "plans" : id
  }).then(function (response) {
    window.snap.pay(response.data.redirect_url)
  }).catch(function (error) {
    //console.log(error);
  });
}

function cancelSubscription(context,invoice){
  const token = context.props.redux.FirebaseToken
  postEvent3('Subscription Event','cancle plan',invoice)
  axios.post(API_URL+'/features/unsubscribe', {
    "firebase_token": token,
    "invoice" : invoice
  }).then(function (response) {
    window.location.reload()
  }).catch(function (error) {
  });
}

async function addMember(item,context){
  
  const invoice = context.state.plans[context.state.activeEduPlan]['invoice']
  const plans_name = context.state.plans[context.state.activeEduPlan]['plans_name']
  context.setState({
    loading:true
  })
  
  if(item.FirebaseID !== ''){
    
    setTimeout(async ()=>{
      
      if(context.state.list_member_array.length < context.state.plans[context.state.activeEduPlan]['sharing_users']['sharing_users']){
        const id = item.FirebaseID
        const token = await getFirebaseToken()
        postEvent3('Education Event','Add Member',id)
        axios.post(API_URL+'/features/addmember',{
          "firebase_token": token,
          "uuid" : id,
          'invoice' : invoice,
          'plans_name' : plans_name
        }).then(function (response) {
          var plans = context.state.plans
          plans[context.state.activeEduPlan]['member'] = plans[context.state.activeEduPlan]['member'].concat(item)
          item.role = 'Member'
          context.setState({
            member_array:[],
            list_member_array:context.state.list_member_array.concat(item),
            loading:false,
            educationMemberDialog:false,
            educationListMemberDialog:true,
            plans:plans,
            choosenMember:{
              Username:'',
              FirebaseID:'',
            }
          })
        }).catch(function (error) {
          //console.log(error);
          
          context.setState({
            loading:false,
            choosenMember:{
              Username:'',
              FirebaseID:'',
            }
          })
        });
      } else {
        
        context.setState({
          loading:false,
          choosenMember:{
            Username:'',
            FirebaseID:'',
          }
        })
      }
    },500)
  } else {
    
    context.setState({
      loading:false,
      choosenMember:{
        Username:'',
        FirebaseID:'',
      }
    })
  }
  
}

async function deleteMember(item,index,context){
  const invoice = context.state.plans[context.state.activeEduPlan]['invoice']
  context.setState({
    loading:true
  })
  
  setTimeout(async ()=>{
    if(invoice !== ''){
      const id = item.FirebaseID
      const token = await getFirebaseToken()
      postEvent3('Education Event','Delete Member',id)
      axios.post(API_URL+'/features/deletemember',{
        "firebase_token": token,
        "uuid" : id,
        'invoice' : invoice
      }).then(function (response) {
        var newPlans = context.state.plans
        newPlans[context.state.activeEduPlan]['member'].splice(index,1)
        
        item.role = 'Member'
        
        context.setState({
          member_array:[],
          loading:false,
          educationMemberDialog:false,
          educationListMemberDialog:true,
          plans:newPlans
        })
        document.getElementById('input-member').value = ''
        document.getElementById('input-member').disabled = false
        
      }).catch(function (error) {
        //console.log(error);
        context.setState({
          loading:false
        })
      });
    } else {
      context.setState({
        loading:false
      })
    }
  },500)
  
}

const plansLogoHandler = (item) => {
  if(item.plans_name === 'PREMIUM PLAN') {
    return require('../../Assets/icon/premium-plan-icon.png')
  } else if (item.plans_name === 'MEDIUM PLAN'){
    return require('../../Assets/icon/medium-plan-icon.png')
  } else if (item.plans_name === 'BASIC PLAN'){
    return require('../../Assets/icon/basic-plan-icon.png')
  } else if (item.plans_name === 'FREE PLAN'){
    return require('../../Assets/icon/free-plan-icon.png')
  } else if (item.plans_name === 'PLAN A'){
    return require('../../Assets/icon/plan-a-icon.png')
  } else {
    return require('../../Assets/icon/plan-b-icon.png')
  }
}   

const plansLogoHandlerByName = (name) => {
  if(name === 'PREMIUM') {
    return require('../../Assets/icon/premium-plan-icon.png')
  } else if (name === 'MEDIUM'){
    return require('../../Assets/icon/medium-plan-icon.png')
  } else if (name === 'BASIC'){
    return require('../../Assets/icon/basic-plan-icon.png')
  } else if (name === 'FREE'){
    return require('../../Assets/icon/free-plan-icon.png')
  } else if (name === 'PLAN A'){
    return require('../../Assets/icon/plan-a-icon.png')
  } else {
    return require('../../Assets/icon/plan-b-icon.png')
  }
}   

function renameLabelStart(context){
  context.rename = true
}

function renameLabelFinish(context,item,text,index){
  const token = context.props.redux.FirebaseToken
  if(context.rename){
    axios.post(API_URL+'/features/editlabel',{
        "firebase_token": token,
        'invoice' : item.invoice,
        'rename':text
    }).then(function () {
        console.log('rename success')
        var notif = document.getElementById("rename-notif"+index);
        notif.classList.remove("hidden");
        setTimeout(function(){
          notif.classList.add("hidden");
        },1000)
    }).catch(function (error) {
        console.log(error);
    });
  }
  context.rename = false
}

async function getTotalFeatures(context){
  const userID = context.props.redux.AccountData.FirebaseID

  axios.get(API_URL+'/features/total/'+userID).then(res => {
    const data = res.data
    
    context.setState({
      userTotalQuota:data.quota,
      userTotalUsedQuota:data.quota_used,
      userTotalMarker:data.marker
    })

  })
}

export {
    searchUsername,
    searchProfile,
    fetchUserProfile,
    imageHandler,
    stringHandler,
    plansLogoHandler,
    renewSubscription,
    addMember,
    deleteMember,
    plansLogoHandlerByName,
    cancelSubscription,
    renameLabelStart,
    renameLabelFinish,
    getTotalFeatures
}