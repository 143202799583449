import React from 'react'
import './index.css'
import {connect} from 'react-redux'
import {withRouter,Link} from 'react-router-dom'
import {
    handleInput,
    registerHandler
} from './action'
import { onLoginSuccess, loginHandler2,loginHandler3 } from '../Login/action'
import {LoadingBar} from '../../Function/LoadingBar'

class Register extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            termsAgreement:false,
            errorDialog:false,
            errorMessage:"",
        }
        this.fullName = ""
        this.Email = ""
        this.Username = ""
        this.Password = ""
        this.Password2 = ""

        this.onLoginSuccess = onLoginSuccess
        this.loginHandler2 = loginHandler2
        this.loginHandler3 = loginHandler3
    }

    render(){
        const context = this
        return(
            <div className="login-parent-container">
                <img className="login-logo" alt='assemblr-logo' src={require('../../Assets/icon/assemblr_logo.svg')}/>

                <div className="login-form-container">

                    <p className="login-input-label">Full Name</p>
                    <div className="username-input-container">
                        <input 
                            className="login-input-container"
                            placeholder="Enter your full name"
                            onChange={(event) => handleInput(this,"fullName",event.target.value)}
                        />
                    </div>

                    <p className="login-input-label">Email</p>
                    <div className="username-input-container">
                        <input 
                            className="login-input-container"
                            placeholder="Enter your email"
                            onChange={(event) => handleInput(this,"Email",event.target.value)}
                        />
                    </div>
                    

                    <p className="login-input-label">Username</p>
                    <div className="username-input-container">
                        <input className="username-input-label" value="@"/>
                        <input 
                            className="username-input"
                            placeholder="Username"
                            onChange={(e)=>{
                                const text = e.target.value
                                if (/^[a-zA-Z0-9]+$/.test(text) || text === "") {
                                    context.Username = text.toLowerCase()
                                    e.target.value = text.toLowerCase()
                                } else {
                                    e.target.value = context.Username
                                }
                            }}
                        />
                    </div>
                    

                    <p className="login-input-label">Password</p>
                    <div className="username-input-container">
                        <input 
                            type="password"
                            className="login-input-container"
                            placeholder="Password"
                            onChange={(event) => handleInput(this,"Password",event.target.value)}
                        />
                    </div>

                    <p className="login-input-label">Confirm Password</p>
                    <div className="username-input-container">
                        <input 
                            type="password"
                            className="login-input-container"
                            placeholder="Password"
                            onChange={(event) => handleInput(this,"Password2",event.target.value)}
                        />
                    </div>

                    <label className="checkbox-container">
                        <input 
                            type="checkbox" 
                            onClick={()=>{
                                this.setState({
                                    termsAgreement:!this.state.termsAgreement
                                })
                            }} 
                            checked={this.state.termsAgreement}
                        /> 
                            {"  I agree to Assemblr's "}<Link onClick={()=>window.open("https://assemblrworld.com/pages/privacy-policy","_blank")}>terms and conditions</Link>
                    </label>

                    <button 
                        onClick={()=>registerHandler(this)} 
                        className="login-button1">Register
                    </button>

                    <p className="or">or</p>
                    
                    <div className='auth-btn-container'>
                        <button onClick={()=>this.loginHandler2(this)} className="login-button2">Facebook</button>
                        <button onClick={()=>this.loginHandler3(this)} className="login-button2">Apple ID</button>
                    </div>
                </div>

                <p className="login-caption">Already Register?</p>
                <p onClick={()=>this.props.history.replace('/Auth/Login')} className="link-register cursor-class">Login Instead</p>


                {this.state.loading? <LoadingBar/>:null}
                {this.state.errorDialog? <div className="error-dialog">{this.state.errorMessage}</div>:null} 
            </div>
        )
    }

}
function mapDispatchToProps(dispatch){
    return {
        handleLogin : (data) => dispatch({type:'LOGIN_USER',data:data}),
    }
}

const mapStateToProps = (state) => {
    return {
        redux : state
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Register))