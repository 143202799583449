import './index.css'
import React from 'react'

const LoadingBar = () => {
    return (
        <div className="top-loader">
            <div className='loader-meter'/>
        </div>
    )
}

const LoadingBar2 = () => {
    return (
        <div className="top-loader2">
            <div className='loader-meter2'/>
        </div>
    )
}

export { 
    LoadingBar,
    LoadingBar2
}