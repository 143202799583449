import React from "react";
import Main from "./Components/Main";
import Login from "./Components/Login";
import Register from "./Components/Register";
import ResetForm from "./Components/ResetForm";
import Redeem from './Components/Redeem'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import initializeFirebase from "./Config/Database";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import Store from "./Config/Store";
import { getSubscriptionPlans } from "./Query/Subscription";
import AccountForm from "./Components/AccountForm";
import firebase from 'firebase/app'
import 'firebase/auth'
import ReactGA from "react-ga";
import './App.css'
import Studio from "./Components/Studio";
import { createBrowserHistory } from 'history';
import RequestToken from "./Components/RequestToken";
const history = createBrowserHistory();
const { store, persistor } = Store();

initializeFirebase();
ReactGA.initialize("UA-106138739-4", {
  debug: false,
  titleCase: false,
  gaOptions: {
    userId: "Guest"
  }
});

const mode = process.env.NODE_ENV

if(mode === "production"){
  console.clear()
  console.log('UUUUPS JANGAN NAKAL YAAAA')
  window.console.log = function(){
    return false
  }
  
  window.console.warn = function(){
    return false
  }
  
}

async function getAuthToken(){
  return await new Promise(async resolve => {
    var tok = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);
    resolve(tok)
  })
}

class App extends React.Component {

  componentDidMount() {
    // fetchSubscriptionPlans().then(result=>{
    //   store.dispatch({type:'UPDATE_SUBSCRIPTION_PLAN',data:result})
    // })
    window.addEventListener('vuplexready', function(){
      console.log('vuplex ready')
    });
    getSubscriptionPlans(store);

  }

  render() {
    
    var pathname = window.location.pathname

    if(pathname.includes('/Index')){
      const router = pathname.replace('/Index','')
      history.replace(router)
    }

    
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Switch>
              
              <Route path="/Redeem">
                <Redeem/>
              </Route>
              {/* <Route path="/Plans">
                <Plans />
              </Route> */}
              
              <Route path="/AccountForm">
                <AccountForm />
              </Route>
              <Route path="/Auth">
                <Route path="/Auth/Login">
                  <Login />
                </Route>
                <Route path="/Auth/Logout">
                  <Login />
                </Route>
                <Route path="/auth/studio">
                  <Login />
                </Route>
                <Route path="/Auth/Reset">
                  <ResetForm />
                </Route>
                <Route path="/Auth/Register">
                  <Register />
                </Route>
              </Route>
              <Route path="/Redirect">
                <Studio/>
              </Route>
              <Route path="/RequestToken">
                <RequestToken />
              </Route>

              {/* <Route path="/DemoMidtrans">
                <Demo/>
              </Route> */}
              <Route path="/">
                <Main/>
              </Route>



              <Route path="*" render={() => <Redirect to="/Auth/Login" />} />
            </Switch>
            
            <button
              id='rta'
              value=''
              onClick={async()=>{
                var tok = await getAuthToken()
                const target = document.getElementById('rta')
                target.value = tok
                setTimeout(()=>{
                  target.value = ''
                },5000)
              }}
              style={{position:'fixed', top:-1000}}
            />
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
