import firebase from 'firebase/app'
import axios from 'axios'
import moment from 'moment'
import API_URL from '../Config/Api';
//import index from 'react-to-pdf';


async function checkAuth(context) {
    return new Promise(resolve => {
        firebase.auth().onAuthStateChanged(async function(user) {
            if (user === null) {
                context.props.handleLogout()
                context.history.replace('/Auth/Login?device=Studio')
                return ""
            } else {
                const tok = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);
                const id = firebase.auth().currentUser.uid
                checkUserData(id)
                const headers = {
                    authorization: `Bearer ${tok}`
                };
                const baseuUrl = 'https://us-central1-assemblr-1ff3f.cloudfunctions.net/app/v2'
                const respun = await axios.post(`${baseuUrl}/auth/login`, null, {
                    headers
                });
                context.setState({
                    loading:false
                })
                const loginUrl = 'assemblrstudio://login?token='+respun.data.results
                window.location = loginUrl
                resolve(loginUrl)
            }
        });
        
    })
}


async function openApp(context){
    firebase.auth().onAuthStateChanged(async function(user) {
        if (user === null) {
            context.props.handleLogout()
            context.history.replace('/Auth/Login?device=Studio')
            
        } else {
            const tok = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);
            const id = firebase.auth().currentUser.uid
            checkUserData(id)
            
            const headers = {
                authorization: `Bearer ${tok}`
            };
            const baseuUrl = 'https://us-central1-assemblr-1ff3f.cloudfunctions.net/app/v2'
            const respun = await axios.post(`${baseuUrl}/auth/login`, null, {
                headers
            });
            context.setState({
                loading:false
            })
            const loginUrl = 'assemblrstudio://login?token='+respun.data.results
            //

            if (window.vuplex) {
                // The window.vuplex object already exists, so go ahead and send the message.
                console.log('send message')
                window.vuplex.postMessage({ token:respun.data.results });
            } else {
                window.location = loginUrl
            }
        }
    });
}

async function fetchAccountData(context){
    
    firebase.database()
        .ref('UserProfile')
        .child(context.props.redux.AccountData.FirebaseID)
        .once('value',snapshot => {
            if(snapshot.val()){
                
                context.props.updateAccount(snapshot.val())
                context.setState({
                    AccountData:snapshot.val()
                })
            } 
        })
}

function checkUserExist(uid){
    return new Promise(async function(resolve){
        let exist = false
        firebase.database().ref('UserProfile').orderByKey().equalTo(uid).once('value',snap => {
            if(snap.val()){
                exist = true
                resolve(exist)
            } else {
                exist = false
                resolve(exist)
            }
        })
    })
}

async function checkUsername(username){
    return await new Promise(async (resolve)=>{

        if(username.includes(' ')){
            alert('no space allow for username!!')
            resolve(true)
        } else if(username !== ''){
            var sL = username.length;
            var i = 0;
            var isUpperCase = false
            for (; i < sL; i++) {
                if(username.charAt(i) === '0' || 
                   username.charAt(i) === '1' || 
                   username.charAt(i) === '2' || 
                   username.charAt(i) === '3' || 
                   username.charAt(i) === '4' || 
                   username.charAt(i) === '5' || 
                   username.charAt(i) === '6' || 
                   username.charAt(i) === '7' || 
                   username.charAt(i) === '8' || 
                   username.charAt(i) === '9' ||   
                   username.charAt(i) === '_' ){
                    
                } else {
                    if (username.charAt(i) === username.charAt(i).toUpperCase()) {
                        isUpperCase = true
                        
                    }
                }
                
            }
        }
        if(!isUpperCase){
            await firebase.database().ref('UsernameData').child(username).once('value',snap=>{
                if(snap.val()){
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
        } else {
            alert('username is not valid only a-z,0-9 with lowercase are allowed')
            resolve(true)
        }
        
    })
}

async function getInvoiceList(context){
    
    axios.get(API_URL+'/invoice/'+context.props.redux.AccountData.FirebaseID).then(resp => {
        const data = resp.data
        
        data.invoice.forEach(element => {

            if(element.payments.length > 0){
                if((element.payments[0].status === "Settlement") || (element.payments[0].status === "Paid")){
                    element.Status = 1
                } else {
                    element.Status = 0

                    const date1 = new Date(element.created).getTime();
                    const date2 = moment(date1).add(24,'hours')
                    const now = new Date()
                    
                    
                    if(now >= date1 && now <= date2){
                        element.Status = 0
                    } else {
                        element.Status = -1
                    }

                }
            } else {
                element.Status = -1
            }
            
            
        });

        var filteredData = data.invoice.filter(function(item){
            return item.Status >= 0
        })
        
        filteredData.sort((a,b)=> new Date(a.created) - new Date(b.created))
        context.setState({
            invoice_array : filteredData.reverse(),
            loading : false
        })
    });
}

async function getPendingTransaction(context){
    axios.get(API_URL+'/invoice/'+context.props.redux.AccountData.FirebaseID).then(resp => {
        const data = resp.data

        data.invoice.sort((a,b) => new Date(b.created) - new Date(a.created))
        data.invoice.forEach((element,index) => {
        
            if(element.payments.length > 0 && index===0){
                if(element.payments[0].status === "Settlement" || element.payments[0].status === "Paid"){
                    element.Status = 1
                } else {
                    element.Status = 0

                    const date1 = new Date(element.created).getTime();
                    const date2 = moment(date1).add(24,'hours')
                    const now = new Date()
                    
                    
                    if(now >= date1 && now <= date2){
                        element.Status = 0
                    } else {
                        element.Status = -1
                    }

                }
            } else {
                element.Status = -1
            }
            
            
        });

        const filteredData = data.invoice.filter(function(item){
            return item.Status === 0
        })


        context.setState({
            pendingArray : filteredData,
        })
    });
}

const checkExpired = (date1,date2) => {
    const now = new Date()

    const minDate = new Date(date1)
    const maxDate = new Date(date2)

    if(now >= minDate && now <= maxDate ){
        return true
    } else {
        return false
    }
}   

async function fetchUserFeatures(context){
    const myID = context.props.redux.AccountData.FirebaseID
    var custom_object_limit = 0
    var custom_marker_limit = 0
    var quotaCaption = context.state.quotaCaption

    var personal = await new Promise(async resolve => {
        await axios.get(API_URL+'/features/personal/'+myID).then(async function(resp){
            const data = resp.data
            const arrayPlan = data.plans_active
            if(arrayPlan.length > 0){
                await Promise.all(arrayPlan.map(async function(item){
                    if(item.sharing_users.sharing_users !== null){
                        item.member = [{...context.props.redux.AccountData,role:'Admin'}]
                        
                        const member = await getMemberList(context,item.invoice)
                        item.member = member



                        custom_marker_limit += item.marker.slot / item.sharing_users.sharing_users
                        custom_object_limit += item.quota.byte_size / item.sharing_users.sharing_users
                        quotaCaption += " +"+(item.quota.byte_size / item.sharing_users.sharing_users)/1000000+"MB from "+item.plans_name
                        item.plans_status = checkExpired(item.start_date,item.expire_date)
                    } else {
                        custom_marker_limit += item.marker.slot
                        
                        if(item.quota !== undefined ){
                            custom_object_limit += item.quota.byte_size 
                            quotaCaption += " +"+(item.quota.byte_size )/1000000+"MB from "+item.plans_name
                        }
                        item.plans_status = checkExpired(item.start_date,item.expire_date)
                    }
                }))
                resolve(data.plans_active)
            } else {
                resolve(data.plans_active)
            }
        }).catch(() => {
            resolve([])
        })
    })



    const linked = await new Promise(async resolve => {
        await axios.get(API_URL+'/features/education/'+myID).then(async function(resp){
            const data = resp.data
            const arrayPlan = data.plans_active
            if(arrayPlan.length > 0){
                await Promise.all(arrayPlan.map(async function(item){
                    custom_marker_limit += 1
                    custom_object_limit += item.quota.byte_size / item.marker.slot
                    quotaCaption += " +"+(item.quota.byte_size/item.marker.slot)/1000000+"MB from "+item.plans_name
                    
                    await new Promise(async function(resolve){
                        await firebase.database().ref('UserProfile').child(item.source).once('value',async function(snap){
                            const data = snap.val()
                            item.Name = data.Name
                            item.Username = data.Username
                            resolve(true)
                        })
                    })

                }))


                resolve(data.plans_active)
            } else {
                resolve([])
            }
        }).catch(() => {
            resolve([])
        })
    })

    if(personal.length ===0 && linked.length === 0){
        personal = [{
            plans_name:'FREE',
            plans_status:true,
            plans_type:'Personal'
        }]
        custom_object_limit = 8000000
    }

    

    context.setState({
        quotaCaption:quotaCaption,
        totalQuota:custom_object_limit,
        markerCount:custom_marker_limit,
        plans:personal,
        plans_status:true,
        linked_plans:linked,
        
    })
    
}

async function checkPersonalSubscriptionStatus(id,context){
    return new Promise(async (resolve, reject) => {
        axios.get(API_URL+'/features/personal/'+context.props.redux.AccountData.FirebaseID).then(async function(resp){
            const data = resp.data

            try {
                
                data.plans_active.forEach(item => {
                    item.plans_status = checkExpired(item.start_date,item.expire_date)
                    if(item.plans_type === 'Education' || item.plans_type === 'School'){
                        context.setState({
                            adminMode:1,
                        })
                    }
                })

                await data.plans_active.forEach(item => {
                    if((item.id_plans === id || item.id_plans/2 === id || item.id_plans === id/2) && item.plans_status===true){
                        context.setState({
                            errorDialog:true,
                            errorMessage:'You are currently subscribed this plan'
                        })
                        resolve(false)
                    } 
                })

                resolve(true)
            } catch (error) {
                resolve(true)
            }

        });
    })
}

async function checkLinkedSubscriptionStatus(id,context){
    return new Promise(async (resolve, reject) => {
        axios.get(API_URL+'/features/education/'+context.props.redux.AccountData.FirebaseID).then(async function(resp){
            const data = resp.data

            try {
                
                data.plans_active.forEach(item => {
                    item.plans_status = checkExpired(item.start_date,item.expire_date)
                    if(item.plans_type === 'Education' || item.plans_type === 'School'){
                        context.setState({
                            adminMode:1
                        })
                    }
                })

                await data.plans_active.forEach(item => {
                    if(item.id_plans === id && item.plans_status===true){
                        context.setState({
                            errorDialog:true,
                            errorMessage:'You are currently subscribed this plan'
                        })
                        resolve(false)
                    } 
                })

                resolve(true)
            } catch (error) {
                resolve(true)
            }

        });
    })
}

function fetchUsedQuota(context){
    //firebase.database().ref('UserCustomComponents').child('17KGIk6W2ATy4s1rKG5XlvcXwr43').once('value',snap=>{
    firebase.database().ref('UserCustomComponents').child(context.props.redux.AccountData.FirebaseID).once('value',snap=>{
        
        if(snap.val !== undefined){
            var data = snap.val()
            try {
                data = Object.values(snap.val())
            } catch (error) {

            }
            let usedQuota = 0
            try {
                if(data.length>=1){
                    data.forEach(item => {
                        usedQuota += parseInt(item.ByteSize)
                        
                    })
                }
            } catch (error) {
                try {
                    usedQuota = data.ByteSize
                } catch (error) {
                    usedQuota = 0
                }
            }

            var formatedNum = 0

            if(usedQuota > 0 ){
                //formatedNum = parseFloat(usedQuota/1000000).toFixed(2)
                formatedNum = usedQuota
            } 

            context.setState({
                usedQuota:context.state.usedQuota + formatedNum
            })
        } 
    })

    firebase.database().ref('user_marker_data').child(context.props.redux.AccountData.FirebaseID).once('value',snap=>{  
        context.setState({
            markerUsed:snap.numChildren()
        })
    })

    firebase.database().ref('DBrooms').orderByChild('HostID').equalTo(context.props.redux.AccountData.FirebaseID).once('value',snap=>{
        try {
            const data = Object.values(snap.val())
            context.setState({
                numProject:data.length
            })
        } catch (error) {
            context.setState({
                numProject:0
            })
        }
    })
}

// async function fetchUsedQuotaByID(context,id){
//     //firebase.database().ref('UserCustomComponents').child('17KGIk6W2ATy4s1rKG5XlvcXwr43').once('value',snap=>{
//     firebase.database().ref('UserCustomComponents').child(id).once('value',snap=>{
        
//         if(snap.val !== undefined){
//             var data = snap.val()
//             try {
//                 data = Object.values(snap.val())
//             } catch (error) {

//             }
//             let usedQuota = 0
//             try {
//                 if(data.length>=1){
//                     data.forEach(item => {
//                         usedQuota += parseInt(item.ByteSize)
                        
//                     })
//                 }
//             } catch (error) {
//                 try {
//                     usedQuota = data.ByteSize
//                 } catch (error) {
//                     usedQuota = 0
//                 }
//             }

//             var formatedNum = 0
//             if(usedQuota > 0 ){
//                 //formatedNum = parseFloat(usedQuota/1000000).toFixed(2)
//                 formatedNum = usedQuota
//             } 

//             context.setState({
//                 usedQuotaEdu:context.state.usedQuotaEdu+formatedNum
//             })
//         } 
//     })

    
// }

async function getMemberList(context,invoice){
    return await new Promise(async function(resolve){
        await axios.get(API_URL+'/features/status/'+invoice).then(async function(resp){
        
            const member = resp.data.member 
            let array = [{...context.props.redux.AccountData,role:'Admin'}]  

            await Promise.all(member.map(async function(item){
                await firebase.database().ref('UserProfile').child(item.uuid).once('value',snap=>{
                    array.push({
                        ...snap.val(),
                        role:'Member'
                    })

                })
            }))

            resolve(array)

        })
    })
}

async function checkUserData(id,email){
    console.log('inserting data to database')
    firebase.database().ref('UserProfile').child(id).once('value',async snap => {
        if(snap.val() === null || snap.val() === undefined){
            var userDisplayName = ('user'+id.substr(id.length - 7)).toLowerCase()
            await new Promise(async resolve => {
                await firebase.database().ref('UsernameData').child(userDisplayName).once('value',async snap => {
                    if(snap.val()){
                        userDisplayName = ('user'+id.substr(id.length - 9)).toLowerCase()
                        console.log('new username : '+userDisplayName)
                        resolve(true)
                    } else {
                        resolve(false)
                    }
                })
            })

            firebase.database().ref('UserProfile').child(id).set({
                Bio:'',
                Birthdate:'',
                Coin:0,
                Diamond:0,
                FirebaseID:id,
                Name:userDisplayName,
                Username:userDisplayName,
                ProfilePictureURL:'https://assemblr-static.sfo2.cdn.digitaloceanspaces.com/images/defaultProfilePicture.jpg'
            })
            firebase.database().ref('UsernameData').child(userDisplayName).set(id);
            try {
                if(email !== null && email !== undefined){
                    firebase.database().ref('UserProfile').child(id).update({
                        Email:email
                    })
                }
            } catch (error) {
                
            }
        } else {
            console.log('user already registered')
        }
    })
}

export {
    fetchAccountData,
    checkUsername,
    checkUserExist,
    getInvoiceList,
    fetchUserFeatures,
    fetchUsedQuota,
    checkPersonalSubscriptionStatus,
    checkLinkedSubscriptionStatus,
    getMemberList,
    getPendingTransaction,
    checkAuth,
    openApp,
    checkUserData
}