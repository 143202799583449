import firebase from 'firebase/app';

function initializeFirebase(){
    var firebaseConfig = {
        databaseURL: process.env.REACT_APP_DATABASE_URL,
        projectId: process.env.REACT_APP_FIREBASE_ID,
        authDomain:process.env.REACT_APP_AUTH_DOMAIN,
        apiKey:process.env.REACT_APP_FIREBASE_KEY,
    }
    try {
        firebase.initializeApp(firebaseConfig);
    } catch (error) {
       //console.log(error);
    }
}

export default initializeFirebase;
