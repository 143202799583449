import Axios from "axios"
import API_URL from "../../Config/Api"


async function getEduPlanList(context){
    const freePlan = {
        "id":0,
        "price":{
            "yearly":'-',
            "monthly":'-',
        },
        "name":"Free",
        "thumbnail":"https://assemblrworld-usa.sfo2.cdn.digitaloceanspaces.com/Education/plan_image/plan-free.png",
        "plans_type":"Education",
        'backgroundColor':'0b9fd6',
        "feature":{
            "educational_contents":false,
            "virtual_class":false,
            "library_access":false,
            "qr_marker":true,
            "offline_access":false,
            "interactivity_builder":false,
            "quota":8000000,
            "marker":0,
            "feature_sharing":0
        },

    }
    await Axios.get(API_URL+'/plans/education/v2').then(res => {
        const result = res.data.plans_educations
        if(result){
            const newPlan = [freePlan, ...result]
            context.setState({
                eduPlans:newPlan
            })
        } else {
            console.log('fetch education plan list error')
        }
    }).catch(err => {
        console.log('fetch education plan list error')
    })
}

async function getPersonalPlanList(context){
    const freePlan = {
        "id":0,
        "price":{
            "yearly":'0',
            "monthly":'0',
        },
        "name":"Free",
        "thumbnail":"https://assemblrworld-usa.sfo2.cdn.digitaloceanspaces.com/Education/plan_image/plan-free.png",
        "plans_type":"Personal",
        'backgroundColor':'0b9fd6',
        "feature":{
            "library_access":false,
            "qr_marker":true,
            "quota":8000000,
            "marker":0,
        },

    }
    await Axios.get(API_URL+'/plans/personal/v2').then(res => {
        const result = res.data.plans_educations
        if(result){
            const newPlan = [freePlan, ...result.reverse()]
            newPlan.sort(function(a, b) {
                return a.price.monthly - b.price.monthly;
            });
            context.setState({
                personalPlans:newPlan
            })
        } else {
            console.log('fetch personal plan list error')
        }
    }).catch(err => {
        console.log('fetch personal plan list error')
    })
}

export {
    getEduPlanList,
    getPersonalPlanList
}