import React from 'react'
import './index.css'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {handleInput, updateAccountData, updatePassword} from './action'
import { postEvent3 } from '../../Function/GoogleAnalitycs'
import {fileHandler} from './action'

class AccountSetting extends React.Component{
    constructor(props){
        super(props)
        this.Name = props.redux.AccountData.Name
        this.Username = props.redux.AccountData.Username
        this.Bio = props.redux.AccountData.Bio
        this.oldPassword = ''
        this.newPassword = ''
        this.newConfirmPassword = ''
        this.state = {
            profileURL : props.redux.AccountData.ProfilePictureURL,
            loading:false,
            changePasswordDialog:false,
        }
        this.UsernameChanged = false
    }

    componentDidMount(){
        postEvent3('Open Page','Account Setting','')
    }


    ChangePasswordContainer = () => {
        var render = null 
        render = (
            <div 
                onClick={()=>{
                    setTimeout(()=>{
                        this.setState({changePasswordDialog:false})
                    },500)
                }} 
                className={this.state.changePasswordDialog?'password-popup-container':'password-popup-container-exit'}
                
                >
                <div 
                    onClick={(event)=>event.stopPropagation()}
                    className={this.state.changePasswordDialog?'password-container':'password-container-exit'}
                >
                    <div className="member-row1">
                        <p className='member-row1-item1'>Change Password</p>
                    </div>

                    <p className="account-input-lbl1">Old Password</p>
                    <input 
                        className="change-password-input1"
                        placeholder="Enter your old password"
                        type="password"
                        onChange={(event) => handleInput(this,"oldPassword",event)}
                    />

                    <p className="account-input-lbl1">New Password</p>
                    <input 
                        className="change-password-input1"
                        placeholder="Enter your new password"
                        type="password"
                        onChange={(event) => handleInput(this,"newPassword",event)}
                    />

                    <p className="account-input-lbl1">Confirm Password</p>
                    <input 
                        className="change-password-input1"
                        placeholder="Confirm your new password"
                        type="password"
                        onChange={(event) => handleInput(this,"newConfirmPassword",event)}
                    />

                    <button
                        onClick={()=>updatePassword(this)} 
                        className='change-password-btn1'>
                        Update
                    </button>
                </div>

                
            </div>
        )
        return render
    }


    render(){
        const context = this
        return(
            <div className='account-parent'>
                <h1 className="centered-text">Account Settings</h1>
                <p className='account-lbl1'>DISPLAY PICTURE</p>

                <img 
                    className='account-avatar' 
                    alt=''
                    src={this.state.profileURL}
                />

                <div className='change-image-container'>
                    <input 
                        type='file' 
                        className='input-file cursor-class' 
                        accept="blob"
                        onChange={ (e) => fileHandler(e.target.files,this) }
                    />
                    <img alt='' className='account-icon1' src={require('../../Assets/icon/ic_camera.png')}/>
                    
                    
                    <p className='account-lbl2'>Change image</p>
                </div>

                <p className="account-input-lbl1">DISPLAY NAME</p>
                <input 
                    className="account-input1"
                    placeholder="Enter your Name here"
                    defaultValue={this.props.redux.AccountData.Name}
                    onChange={(event) => handleInput(this,"Name",event)}
                    
                />

                <p className="account-input-lbl1">USERNAME</p>
                <input 
                    className="account-input1"
                    placeholder="Username"
                    defaultValue={this.props.redux.AccountData.Username}
                    onChange={(e)=>{
                        const text = e.target.value
                        if (/^[a-zA-Z0-9]+$/.test(text) || text === "") {
                            context.UsernameChanged = true
                            context.Username = text.toLowerCase()
                            e.target.value = text.toLowerCase()
                        } else {
                            context.UsernameChanged = true
                            e.target.value = context.Username
                        }
                    }}
                />

                <p className="account-input-lbl1">EMAIL ADDRESS</p>
                <input 
                    className="account-input1"
                    placeholder="Email"
                    value={this.props.redux.AccountData.Email}
                    readOnly
                />

                <p className="account-input-lbl1">BIO</p>
                <input 
                    className="account-input1"
                    placeholder="Descript yourself here"
                    defaultValue={this.props.redux.AccountData.Bio}
                    onChange={(event) => handleInput(this,"Bio",event)}
                />


                <button
                    onClick={()=>updateAccountData(this)} 
                    className='account-btn1'>
                    Submit
                </button>

                {this.props.redux.loginType==="Email"?(
                    <p 
                        className='account-btn2'
                        onClick={()=>{
                            this.setState({changePasswordDialog:true})
                        }}>
                        Change Password
                    </p>
                ):null}

                
                {/* NAVBAR */}
                


                {this.state.loading? <div className="loader"/>:null}

                {this.ChangePasswordContainer()}
            </div>
        )
    }

}

function mapDispatchToProps(dispatch){
    return {
        handleLogout : () => dispatch({type:'LOGOUT_USER'}),
    }
}
const mapStateToProps = (state) => {
    return {
        redux : state
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(AccountSetting))