import axios from "axios";
import firebase from 'firebase/app'
import 'firebase/auth'
import API_URL from "../../Config/Api";

async function orderHandler(token, plans_id, context) {
  context.setState({
    loading:true
  })
  setTimeout(()=>{
    var id = plans_id;
    //console.log(id)
    if (token !== "" && id !== '1' && id !== '5' && id!==1 && id!==5) {
      //let status1 = await checkPersonalSubscriptionStatus(id, context);
      //let status2 = await checkLinkedSubscriptionStatus(id, context);
      // console.log({
      //   firebase_token: token,
      //   plans: id,
      //   quantity:1,
      // })
      
      
        axios.post(API_URL+"/plans/request", {
          firebase_token: token,
          plans: id,
          quantity:1,
        }).then(function(response) {
          window.snap.pay(response.data.transaction_token)
          context.setState({
            loading:false
          })
        }).catch(function(error) {
          var message = error.response.data.error_message
          if(message === "firebase_token: Invalid Token"){
            message = "your session has expired. please relogin"
          }
          context.setState({
            loading:false,
            errorDialog : true,
            errorMessage : message
          })
          //console.log(error);
        });
      
        
      
    } else {
      if(token === ''){
        context.props.history.push("/Auth/Login?plans_id=" + id);
      }
      context.setState({
        loading:false
      })
    }
    
  },500)
}

function directOrder(token, id,context) {
  context.props.history.push('/Plans')
  if(id !== '1' && id !=='5'){
    console.log('processing your order')
    axios
    .post(API_URL+"/plans/request", {
      firebase_token: token,
      plans: id,
      quantity:1,
    })
    .then(function(response) {
      
      window.snap.pay(response.data.transaction_token)
    })
    .catch(function(error) {
      context.setState({
        loading:false,
        errorDialog : true,
        errorMessage : error.response.data.error_message
      })
    });
  } else {
    console.log('item is free')
  }
}

async function buyMarker(context,qty){
  const token = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);
  
  axios
    .post(API_URL+"/plans/marker", {
      firebase_token: token,
      quantity:qty,
    })
    .then(function(response) {
      
      window.snap.pay(response.data.transaction_token)
      context.setState({loading:false})
    })
    .catch(function(error) {
      context.setState({
        loading:false,
        errorDialog : true,
        errorMessage : error.response.data.error_message
      })
    });
}

const filterPlans = context => {

  const temp = context.props.redux.SubscriptionPlansPersonal;
  const temp2 = context.props.redux.SubscriptionPlansEducation;
  
  
  const monthly = temp;
  const annually = temp;



  var monthlyPersonal = monthly.filter(item => {
    return item.duration === 31;
  });

  //monthlyPersonal.concat(monthly[4])

  const annuallyPersonal = annually.filter(item => {
    return item.duration === 365;
  });

  const educationIndividu = temp2.filter(item => {
    return item.name === 'Individual'
  })

  const educationSchool = temp2.filter(item => {
    return item.name !== 'Individual'
  })
  annuallyPersonal.sort(function(a, b){
    return parseFloat(a.price.replace('$','')) - parseFloat(b.price.replace('$',''))
  });

  monthlyPersonal.sort(function(a, b){
    return parseFloat(a.price.replace('$','')) - parseFloat(b.price.replace('$',''))
  });

  educationSchool.sort(function(a, b){
    return parseFloat(a.price.replace('$','')) - parseFloat(b.price.replace('$',''))
  });


  if (
    context.state.subscriptiomMode === 0 &&
    context.state.activeCategory === 0
  ) {
    return monthlyPersonal;
  } else if (
    context.state.subscriptiomMode === 1 &&
    context.state.activeCategory === 0
  ) {
    return annuallyPersonal;
  } else if (context.state.activeCategory === 1 && context.state.educationType === 0) {
    return context.state.education_plans_array.concat(educationIndividu);
  } else {
    return context.state.education_plans_array.concat(educationSchool);
  }
};

const getUrlParameter = name => {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  let results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

export { orderHandler, buyMarker, getUrlParameter, directOrder, filterPlans };
