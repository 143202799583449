const initialState = {
    AccountData:{
        Username:'',
        FirebaseID:'',
        ProfilePictureURL:'',
        Name:'',
        Bio:'',
        Email:'',
    },
    isLoggedIn:false,
    loginType:'',
    SubscriptionPlansPersonal:[],
    SubscriptionPlansEducation:[],
    FirebaseToken:'',
}
  
//Reducer
const mainReducer = (state = initialState, action) =>{
    switch(action.type){
        case 'LOGIN_USER':
            return{
                ...state,
                AccountData:action.data.userData,
                isLoggedIn:true,
                loginType:action.data.loginType
            }
        case 'LOGOUT_USER':
            return{
                ...state,
                AccountData:{
                    Username:'',
                    FirebaseID:'',
                    ProfilePictureURL:'',
                    Name:'',
                    Bio:'',
                    Email:'',
                },
                isLoggedIn:false,
                FirebaseToken:'',
            }
        case 'UPDATE_SUBSCRIPTION_PLAN_PERSONAL':
            return {
                ...state,
                SubscriptionPlansPersonal:action.data
            }
        case 'UPDATE_SUBSCRIPTION_PLAN_EDUCATION':
            return {
                ...state,
                SubscriptionPlansEducation:action.data
            }
        case 'UPDATE_ACCOUNT':
            return{
                ...state,
                AccountData:action.data,
            }
        case 'UPDATE_TOKEN':
            return {
                ...state,
                FirebaseToken:action.data
            }
        default:
        return state;
    }
}
  
export default mainReducer;
  