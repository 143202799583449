import React from "react";
import "./index.css";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { handleInput, setAccountInformation } from "./action";
// import { postEvent3 } from '../../Function/GoogleAnalitycs'

class AccountForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      termsAgreement: false,
      errorDialog: false,
      errorMessage: ""
    };
    this.fullName = "";
    this.Email = props.redux.AccountData.Email;
    this.Username = "";
  }

  componentDidMount() {}

  render() {
    return (
      <div className="login-parent-container">
        <img
          className="login-logo"
          alt="assemblr-logo"
          src={require("../../Assets/icon/assemblr_logo.svg")}
        />

        <div className="login-form-container">
          <p className="login-input-label">Full Name</p>
          <div className="username-input-container">
            <input
              className="login-input-container"
              placeholder="Enter your full name"
              onChange={event =>
                handleInput(this, "fullName", event.target.value)
              }
            />
          </div>

          <p className="login-input-label">Email</p>
          <div className="username-input-container">
            <input
              defaultValue={this.props.redux.AccountData.Email}
              className="login-input-container"
              placeholder="Enter your email"
              onChange={event => handleInput(this, "Email", event.target.value)}
            />
          </div>

          <p className="login-input-label">Username</p>
          <div className="username-input-container">
            <input className="username-input-label" value="@" />
            <input
              className="username-input"
              placeholder="Username"
              onChange={event =>
                handleInput(this, "Username", event.target.value)
              }
            />
          </div>

          <label className="checkbox-container">
            <input
              type="checkbox"
              onClick={() => {
                this.setState({
                  termsAgreement: !this.state.termsAgreement
                });
              }}
              checked={this.state.termsAgreement}
            />
            I agree to Assemblr's{" "}
            <Link
              onClick={() =>
                window.open(
                  "https://assemblrworld.com/pages/privacy-policy",
                  "_blank"
                )
              }
            >
              terms and conditions
            </Link>
          </label>

          <button
            onClick={() => setAccountInformation(this)}
            className="login-button1"
          >
            Save
          </button>
        </div>

        {this.state.loading ? <div className="loader" /> : null}
        {this.state.errorDialog ? (
          <div className="error-dialog">{this.state.errorMessage}</div>
        ) : null}
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleLogin: data => dispatch({ type: "LOGIN_USER", data: data })
  };
}

const mapStateToProps = state => {
  return {
    redux: state
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountForm));
