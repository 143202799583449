import React from 'react'
import './index.css'
import {withRouter} from 'react-router-dom'
import {handleInput,resetPassword} from './action'



class ResetForm extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            ...props.redux,
            loading:false,
            errorDialog:false,
            errorMessage:'Please fill in all form'
        }

        this.Email =""

        // BINDING ACTION JS TO THIS CLASS

    }

    render(){
        return(
            
            <div className="login-parent-container">
                <img className="login-logo" alt='assemblr-logo' src={require('../../Assets/icon/assemblr_logo.svg')}/>

                <div className="login-form-container">

                    <p className="login-input-label">Email</p>
                    <div className="username-input-container">
                        <input 
                            className="login-input-container"
                            placeholder="Email"
                            onChange={(event) => handleInput(this,"Email",event.target.value)}
                        />
                    </div>
                    <button onClick={()=>resetPassword(this.Email,this)} className="login-button1">Reset my password</button> 
                </div>

                <div style={{height:30}}/>

                <p onClick={()=>this.props.history.goBack()} className="link-register">Back</p>
                {this.state.loading? <div className="loader"/>:null}
                {this.state.errorDialog? <div className="error-dialog">{this.state.errorMessage}</div>:null} 

            </div>
    
        )
    }
}


export default (withRouter(ResetForm))
