import { loadStripe } from '@stripe/stripe-js';
import Axios from 'axios';
import API_URL from '../../Config/Api';
import { navigateTo } from '../../Function/Routing';
import { getFirebaseToken } from '../../Query/FirebaseAuth';
//import queryString from 'query-string'

const baseURL = API_URL
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN);
const checkoutPlan = async (event,context,vendor,id) => {
    var quantity = "1"
    var planID = id


    //SCHOOL PLAN
    if(id === '100') {
      const paymentQty = context.state.paymentQty
      quantity = paymentQty.toString()

      if(paymentQty >= 30 && paymentQty <60){
        planID = '101'
      } else if(paymentQty >= 60){
        planID = '102'
      }
    }

    console.log({planID})

    var url = '/plans/request'
    if(context.buyMarker){
      quantity = context.state.paymentQty
      url='/plans/marker'
    }
    // Get Stripe.js instance
    const stripe = await stripePromise;
    var paymentMethods = ''

    // Call your backend to create the Checkout Session
    const token = await getFirebaseToken()
    
    //console.log(id)

    

    const session = await new Promise(async resolve => {
      await Axios.post(baseURL+url,{
        "firebase_token":token,
        "plans":planID,
        //"plans":'18',
        "quantity":quantity,
        "payments":vendor,
        "success_url":window.location.origin+'/Overview',
        "cancel_url":window.location.origin+window.location.pathname+window.location.search+'&cancel=true',

      }).then(res =>{

        var result = ''

        if(vendor==='1' || vendor==='3'){
          result = res.data.id
        } else {
          result = res.data.token
          paymentMethods = context.state.paymentMethods
        }
        context.setState({loading:false,})

        //console.log(result)
        resolve(result)
      }).catch( err => {
        //console.log(err.response)
        if(err.response){
          
          //console.log(err.response.data.error_message)
          const errorMessage = err.response.data.error_message
          if(errorMessage === 'You Have Plans Educations'){
            alert('You already have active plan')
          } else {
            alert(errorMessage)
          }

        } else {
          alert(err.message)
        }
        
        context.setState({
          loading:false,
        })
        
        resolve('')
        
      })
    })
    //console.log(session)
    if(vendor === "1" || vendor === "3"){
      await stripe.redirectToCheckout({
        sessionId:session
      })
      .then(function(result) {
          console.log(result)
         
      }).catch(err=>{
        console.log(err)
      })
    } else {
      window.snap.pay(session, {
          selectedPaymentType:paymentMethods,
          onSuccess: function(result){
              //console.log('success');
            //   const param1 = ['edu_payment_success_'+paymentMethods]
            //   const param2 = {
            //       eventName:'edu_payment_success_'+paymentMethods,
            //   }
            //   sendEvent(param1, param2)

              sessionStorage.setItem('transaction_id',session)
              sessionStorage.setItem('transaction_status','Success')
              context.props.history.replace('/Invoices')
          },
          onPending: function(result){
              navigateTo(context,'/Invoices')
              //console.log('pending');
              context.setState({
                  onTransaction:'Pending'
              })
              sessionStorage.setItem('transaction_id',session)
              sessionStorage.setItem('transaction_status','Pending')

              // if(context.state.paymentMethod ===2){
              //     context.props.history.replace('/Plans')
              // }
              try {
                  context.props.updateState()
              } catch (error) {
                  
              }
              // setTimeout(()=>{
              //     window.location.reload()
              // },2000)
          },
          onError: function(result){console.log('error');},
          onClose: function(){console.log('customer closed the popup without finishing the payment');}
      })
    }

    

};


const checkoutCustomDurationPlan = async (event,context,vendor,id) => {
  // Get Stripe.js instance
  const quantity = context.state.paymentQty
  var paymentMethods = ''

  // Call your backend to create the Checkout Session
  const token = await getFirebaseToken()

  const session = await new Promise(async resolve => {
    await Axios.post(baseURL+'/plans/custom_duration',{
      "firebase_token":token,
      "plans":id,
      //"plans":'18',
      "quantity":quantity,
      "payments":vendor,
      "success_url":window.location.origin+'/Myplan'+window.location.search+'&pay_success=true',
      "cancel_url":window.location.origin+window.location.pathname+window.location.search+'&cancel=true',

    }).then(res =>{

      var result = ''

      if(vendor==='1' || vendor==='3'){
        result = res.data.id
      } else {
        result = res.data.token
        paymentMethods = context.state.paymentMethods
      }
      context.setState({loading:false,})


      resolve(result)
    }).catch( err => {
      context.setState({
        loading:false,
        notification_err:true,
        notification_errmessage:err.message
      })
      resolve('')
    })
  })
  //console.log(session)
  if(vendor === "1" || vendor === "3"){
    const stripe = await stripePromise;
    await stripe.redirectToCheckout({
      sessionId:session
    })
    .then(function(result) {
        console.log(result)
       
    }).catch(err=>{
      console.log(err)
    })
  } else {
    window.snap.pay(session, {
        selectedPaymentType:paymentMethods,
        onSuccess: function(result){
            console.log('success');
            // const param1 = ['edu_payment_success_'+paymentMethods]
            // const param2 = {
            //     eventName:'edu_payment_success_'+paymentMethods,
            // }
            // sendEvent(param1, param2)

            sessionStorage.setItem('transaction_id',session)
            sessionStorage.setItem('transaction_status','Success')
            context.props.history.replace('/Myplan')
        },
        onPending: function(result){
            navigateTo(context,'/Transaction')
            console.log('pending');
            context.setState({
                onTransaction:'Pending'
            })
            sessionStorage.setItem('transaction_id',session)
            sessionStorage.setItem('transaction_status','Pending')

            // if(context.state.paymentMethod ===2){
            //     context.props.history.replace('/Plans')
            // }
            try {
                context.props.updateState()
            } catch (error) {
                
            }
            // setTimeout(()=>{
            //     window.location.reload()
            // },2000)
        },
        onError: function(result){console.log('error');},
        onClose: function(){console.log('customer closed the popup without finishing the payment');}
    })
  }

  

};

async function getDetailPlan(context, id){
  await Axios.get(baseURL+'/plans/v2/'+id).then(res => {
    const result = res.data
    console.log(res)
    if(result){
        //console.log(result)
        const plan = result.plans
        var planImage = plan.thumbnail
        var planName = plan.name
        var planBackgroundColor = plan.backgroundColor
        const featureValue = Object.values(plan.feature)
        const featureKey = Object.keys(plan.feature)
        var feature = []
        featureKey.forEach((items, index) => {
            if(featureValue[index] === true || featureValue[index]>0){
                switch (items) {
                    case 'educational_contents':
                        feature[index] = 'Unlimited Educational Contents' + ' '
                        break;
                    case 'virtual_class':
                        feature[index] = 'Unlimited Virtual Class' + ' '
                        break
                    case 'library_access':
                        if(plan.plans_type !== 'Personal'){
                          feature[index] = 'Unlimited 3D & 2D Library' + ' '
                        } else {
                          feature[index] = 'Unlimited 2D, 3D & AR Library' + ' '
                        }
                        break
                    case 'qr_marker':
                        feature[index] = 'Unlimited QR Marker' + ' '
                        break
                    case 'offline_access':
                        feature[index] = 'Offline Access' + ' '
                        break
                    case 'quota':
                        if(plan.plans_type !== 'Personal'){
                          feature[index] =  '300MB Storage Per Account'
                        } else if(featureValue[index] > 0){
                          feature[index] =  featureValue[index]/1000000+'MB Storage'
                        }
                        break
                    case 'marker':
                        if(plan.plans_type !== 'Personal'){
                          feature[index] = '5 Custom Marker Per Account'
                        } else if(featureValue[index] > 0){
                          feature[index] =  featureValue[index]+' Custom Marker'
                        }
                        break
                    case 'feature_sharing':
                        feature[index] = featureValue[index] + ' Member Seats'
                        break
                    default:
                        break;
                }
            } 
        })
        var customSeats = false
        var monthlyPrice = 0
        var yearlyPrice = 0
        if(plan.price.monthly){
          monthlyPrice = plan.price.monthly
        }

        if(plan.price.yearly){
          yearlyPrice = plan.price.yearly
        }

        if(plan.price.yearly.length){
          customSeats=true
          //console.log(customSeats)
        }

        if(plan.name.toLowerCase().includes('custom marker')){
          feature = ["Pay once", "Available to use anytime", "No expiration date"]
          customSeats=false
          context.buyMarker = true
        }

        

        const detail = {
          image:planImage,
          name:planName,
          backgroundColor:'#'+planBackgroundColor,
          feature:feature.reverse(),
          monthly:monthlyPrice,
          yearly:yearlyPrice,
          
        }


        console.log({customSeats})

        context.setState({
          plan:detail,
          id_pricing:result.id_pricing,
          customSeats:customSeats
        })
        //console.log('state setted')

    } else {
        context.setState({
          alertVisibilty:true,
          alertLabel:'Plan not available'
        })
    }
  }).catch(err => {
    context.setState({
      alertVisibilty:true,
      alertLabel:'Plan not available'
    })
  })
}


export {
    checkoutPlan,
    checkoutCustomDurationPlan,
    getDetailPlan
}