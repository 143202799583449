import React from 'react'
import './index.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { checkAuth, openApp } from '../../Query/Account'
import { LoadingBar } from '../../Function/LoadingBar'

class Studio extends React.Component{


    constructor(props){
        super(props)
        this.state ={
            loading:true
        }
        this.link = ""
    }

    componentDidMount(){
        this.link = checkAuth(this)
    }

    render(){
        return(
            <div className='studio-parent'>
                <div className='studio-container'>
                    <div className='studio-txt1'>You'll be redirected to <strong>Assemblr Studio App</strong> in a second or two</div>
                    <div 
                        onClick={()=>{
                            openApp(this)
                        }}
                        className='studio-txt2 cursor-class'>or, click here to open Assemblr Studio directly</div>

                    <img alt='' className='studio-logo' src={require('../../Assets/icon/studio_logo.png')}/>
                </div>

                {this.state.loading? <LoadingBar/>:null}
            </div>
        )
    }

}

function mapDispatchToProps (dispatch) {
    return {
        handleLogout : () => dispatch({type:'LOGOUT_USER'}),
    }
}
const mapStateToProps = (state) => {
    return {
        redux : state
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Studio))