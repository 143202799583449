import { createStore } from 'redux';
import {persistStore,persistReducer,createTransform } from "redux-persist"
import mainReducer from './Reducer'
import storage from 'redux-persist/es/storage'
import CryptoJS from 'crypto-js';

const encrypt = createTransform(
    (inboundState, key) => {
      if (!inboundState) return inboundState;
      const cryptedText = CryptoJS.AES.encrypt(JSON.stringify(inboundState),process.env.REACT_APP_STORAGE_KEY);
      return cryptedText.toString(); 
    },
    (outboundState, key) => {
      if (!outboundState) return outboundState;
      const bytes = CryptoJS.AES.decrypt(outboundState, process.env.REACT_APP_STORAGE_KEY);
      const decrypted = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(decrypted);
    },
);
const config ={
    transforms:[encrypt],
    key: "primary",
    storage:storage
}

const persistedReducers = persistReducer(config, mainReducer)
export default () => {
    let store = createStore(persistedReducers)
    let persistor = persistStore(store)
    return { store, persistor }
}