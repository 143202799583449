import firebase from 'firebase/app'
import { showDialog } from "../../Function/Dialog"

function handleInput(context,type,text){
    if(type === "Email"){
        context.Email = text
    } 
}

async function resetPassword(email,context){
    context.setState({
        loading:true
    })
    setTimeout(()=>{
        if(email !== ""){
            firebase.auth().sendPasswordResetEmail(email)
                .then(function () {
                    showDialog("Verification link has been sent to "+email,context)
                }).catch(function (e) {
                    showDialog(e.message,context)
                })
        } else {
            showDialog("Email is empty!! "+email,context)
        }
    },1000)

    
}


export {
    handleInput,
    resetPassword,
}