import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import './index.css'
import '../Plans/index.css'
import { postEvent3 } from '../../Function/GoogleAnalitycs'
import { getEduPlanList, getPersonalPlanList } from './action'
import parse from 'html-react-parser';

let stateKeeper = null

class Plans extends React.Component {
    
    constructor(props){
        super(props)
        this.state ={
            activeCategory:0,
            subscriptionMode:0,
            educationType:0,
            eduPlans:[],
            personalPlans:[],
        }
    }
    
    componentDidMount(){
        
        if(stateKeeper !== null){
            this.setState(stateKeeper)
        }
        const userID = this.props.redux.AccountData.FirebaseID
        const parentContext = this.props.parentContext
        const context = this
        if(userID===''){
            parentContext.setState({
                navbarVisibility:false
            })

            global.redirectPage=window.location.pathname
            context.props.history.replace('/Auth/Login')
        }
        postEvent3('Open Page','Plans Page','')
        getEduPlanList(this)
        getPersonalPlanList(this)
    }

    componentWillUnmount(){
        stateKeeper = this.state
        
    }

    render(){
        const context = this
        const {activeCategory, subscriptionMode, eduPlans, personalPlans} = this.state
        return (
            <div className='plans-page'>
                <div className="wider-container">
                    <h1 className="centered-text">
                        Select a plan which suits your need<br/>
                    </h1>

                    {/* PLANS PICKER ASSEMBLR OR ASSEMBLR EDU */}
                    <div className="plans-category-container" id="plansHeader">
                        {/* ASSEMBLR PLAN */}
                        <div className={activeCategory===0?"plans-category-indicator-active":"plans-category-indicator-inactive"}>
                            <img
                                alt=''
                                src={require('../../Assets/logo/plan_logo1.png')}
                            />
                            <p  
                                className={activeCategory===0?"plans-category-active cursor-class":"plans-category-inactive cursor-class"}
                                onClick={()=>{
                                    postEvent3('Plans Event','Change Subscription Type','Personal')
                                    this.setState({activeCategory:0})
                                }} 
                                >Assemblr Plan
                            </p>
                        </div>
                        {/* ASSEMBLR EDU PLAN */}
                        <div className={activeCategory===1?"plans-category-indicator-active":"plans-category-indicator-inactive"}>
                            <img
                                alt=''
                                src={require('../../Assets/logo/plan_logo2.png')}
                            />
                            <p 
                                onClick={()=>{
                                    postEvent3('Plans Event','Change Subscription Type','Education')
                                    this.setState({activeCategory:1})
                                }} 
                                className={activeCategory===1?"plans-category-active cursor-class":"plans-category-inactive cursor-class"}
                                >Assemblr Edu Plan
                            </p>
                        </div>
                    </div>

                    {activeCategory===0?(
                        <div className="plans-time-picker">
                            <div className="plans-subs-time" style={subscriptionMode===0?{opacity:1}:{opacity:0.5}}>Monthly</div>
                            <div 
                                onClick={()=>{

                                    if(subscriptionMode===0){
                                        postEvent3('Plans Event','Change Subscription Time','Annually')
                                        this.setState({subscriptionMode:1})
                                    } else {
                                        postEvent3('Plans Event','Change Subscription Time','Monthly')
                                        this.setState({subscriptionMode:0})
                                    }
                                }}
                                className="switch-wrap"
                            >    
                                <div 
                                    className="slider" style={subscriptionMode===1?{left:'60%'}:{left:'10%'}}
                                />
                            </div>
                            <div className="plans-subs-time" style={subscriptionMode===1?{opacity:1}:{opacity:0.5}}>Annually</div>
                        </div>
                    ):null}

                    <div className='plans-list-container'>

                        {activeCategory===0?personalPlans.map(item => {
                            var price = subscriptionMode===0?item.price.monthly:item.price.yearly
                            var id = item.id
                            const features = item.feature
                            return (
                                <div 
                                    key={id}
                                    className={'plans-item '+item.name.toLowerCase()}>
                                    <div className='title'>{item.name}</div>
                                    <div className='price'>${price}</div>

                                    {/* {item.features.map((items,index) => {
                                        return (
                                            <div className='detail' key={index}><div>&#10003;</div> <div><strong>{items.strong}</strong> {items.label}</div></div>
                                        )
                                    })} */}

                                    
                                    {features.quota > 0?(
                                        <div className='detail'><div>&#10003;</div> <div><strong>{features.quota/1000000} MB</strong> Storage</div></div>
                                    ):null}

                                    {features.marker > 0?(
                                        <div className='detail'><div>&#10003;</div><div><strong>{features.marker}</strong> Custom Marker</div></div>
                                    ):null}

                                    <div className='detail'><div>&#10003;</div><div><strong>Unlimited </strong> Projects</div></div>
                                    
                                    <div className='detail'><div>&#10003;</div><div><strong>Unlimited </strong> QR Marker</div></div>

                                    {features.library_access?(
                                        <div className='detail'><div>&#10003;</div> <div><strong>Unlimited</strong> 2D, 3D & AR Library</div></div>
                                    ):(
                                        <div className='detail'><div>&#10003;</div> <div><strong>Limited</strong> 2D, 3D & AR Library</div></div>
                                    )}

                                    <button
                                        onClick={()=>{
                                            if(price > 0){
                                                postEvent3('Plans Event','Place Order Plans',id)
                                                context.props.history.push('/Checkout/'+id)
                                            }
                                        }}
                                    >{price > 0?'Buy Package':'Sign Up'}</button>

                                    {(item.discount && subscriptionMode===1)?(
                                        <img
                                            alt=''
                                            src={require('./assets/badge_20.svg')}
                                        />
                                    ):null}
                                </div>
                            )
                        }):null}

                        {activeCategory===1?eduPlans.map(item => {
                            const features = item.feature
                            var renders = (
                                <div 
                                    style={{backgroundColor:'#'+item.backgroundColor,backgroundImage:'url("'+item.thumbnail+'")'}}
                                    className={'plans-item-edu'}>
                                    <div className='title'>{item.name.toLowerCase() !=='individual'?'SCHOOL / INSTITUTION':item.name}</div>
                                    <div className='price'>
                                        {(item.price.yearly.length && item.price.yearly!=='-')?'$'+1:item.price.monthly>0?'$'+item.price.monthly:null} 
                                        {(item.price.yearly.length && item.price.yearly!=='-')?<div>/Seat/Month</div>:item.price.monthly>0?<div>/Month</div>:null} 
                                    </div>

                                    {features.feature_sharing>0?(
                                        item.price.yearly.length>0?(
                                            <div className='detail'><div>&#10003;</div> <div><strong>Custom </strong> Member Seats</div></div>
                                          ):(
                                            <div className='detail'><div>&#10003;</div> <div><strong>{features.feature_sharing}</strong> Member Seats</div></div>
                                        )
                                    ):null}

                                    <div className='detail'><div>&#10003;</div> <div><strong>Unlimited</strong> Virtual Class</div></div>

                                    {features.educational_contents?(
                                        <div className='detail'><div>&#10003;</div> <div><strong>Unlimited</strong> Educational Contents</div></div>
                                    ):null}
                                    
                                    {features.library_access?(
                                        <div className='detail'><div>&#10003;</div> <div><strong>Unlimited</strong> 3D & 2D Library</div></div>
                                    ):null}

                                    {features.feature_sharing >= 1?(
                                        <div className='detail'><div>&#10003;</div> <div><strong>300 MB</strong> Storage Per Account <br />(For Custom 3D Objects)</div></div>
                                    ):(
                                        <div className='detail'><div>&#10003;</div> <div><strong>8 MB</strong> Storage</div></div>
                                    )}
                                    
                                    {features.marker > 0?(
                                        <div className='detail'><div>&#10003;</div> <div><strong>5</strong> Custom marker for each seats</div></div>
                                    ):null}
                                    

                                    <div className='detail'><div>&#10003;</div> <div><strong>Unlimited</strong> QR Marker</div></div>
                                    
                                    {features.offline_access?(
                                        <div className='detail'><div>&#10003;</div> <div>Offline Access (coming soon)</div></div>
                                    ):null}
                                    


                                    {(item.notes !== '-' && typeof(item.notes) === 'string')?(
                                        parse(item.notes)
                                    ):null}
                                    


                                    {/* {item.features.map(items => {
                                        return (
                                            <div className='detail'><div>&#10003;</div> <div><strong>{items.strong}</strong> {items.label}</div></div>
                                        )
                                    })} */}

                                    {/* {item.notes.map(items => {
                                         return (
                                            <div className='notes'>{items}</div>
                                        )
                                    })} */}
                                    {(item.price.monthly > 0 || item.price.yearly.length >= 3 || item.price.yearly>0)?(
                                        <button
                                            onClick={()=>{
                                                postEvent3('Plans Event','Place Order Plans',item.id)
                                                context.props.history.push('/Checkout/'+item.id)
                                            }}
                                        >Buy Package</button>
                                    ):null}

                                    
                                </div>
                            )
                            // if(educationType===0 && !item.personal){
                            //     renders = null
                            // } else if(educationType===1 && !item.school){
                            //     renders = null
                            // }

                            return renders
                        }):null}


                        
                    </div>

                    
                    
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        redux : state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleLogout : () => dispatch({type:'LOGOUT_USER'}),
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Plans))
