import React from 'react'
import ScriptTag from 'react-script-tag'

const Midtrans = () => {
    const midtransProd = (
        <ScriptTag type="text/javascript" 
            src="https://app.midtrans.com/snap/snap.js"
            data-client-key={process.env.REACT_APP_MIDTRANS_KEY}>
        </ScriptTag> 
    )

    // const midtransDev = (
    //     <ScriptTag type="text/javascript" 
    //         src="https://app.sandbox.midtrans.com/snap/snap.js"
    //         data-client-key='SB-Mid-client-9_NnXy84yCDqAUpJ'>
    //     </ScriptTag> 
    // )

    

    return midtransProd
}


export default Midtrans