import React from 'react'
import './index.css'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { checkoutPlan, checkoutCustomDurationPlan, getDetailPlan } from './action';
import {GrClose} from 'react-icons/gr'
import {BsArrowLeft} from 'react-icons/bs'
import queryString from 'query-string'
import { sendEvent } from '../../Function/GoogleAnalitycs';
import Midtrans from '../../Config/Midtrans';

class Checkout extends React.Component {
    constructor(props){
        super(props)
        this.planId = props.match.params.id
        var customSeats = false
        // if(props.match.params.id === '10'){
        //     customSeats = true
        // }
        this.state = {
            paymentMode:0,
            paymentQty:0,
            paymentMethod:0,
            paymentMethods:'bank_transfer',
            loading:false,
            notification_err:false,
            notification_errmessage:'',
            customSeats:customSeats,
            plan:{
                name:'',
                backgroundColor:'',
                plans_type:'',
                thumbnail:'',
                feature:[],
                monthly:0,
                yearly:0
            },
        }
    }

    componentDidMount(){
        window.scrollTo(0, 0)
        const context = this
        const userID = this.props.redux.AccountData.FirebaseID
        
        if(userID===''){

            global.redirectPage=window.location.pathname
            context.props.history.replace('/Auth/Login')
        }
        //document.getElementsByClassName('main-container')[0].scrollTo(0,0)
        
        
        var param = queryString.parse(window.location.search)

        

        if(param.scheme !== undefined){
            switch (param.scheme) {
                case 'subscription':
                    context.setState({
                        paymentMode:1
                    })
                    break;
                case 'onetime':
                    context.setState({
                        paymentMode:2
                    })
                    break;
                default:
                    break;
            }
        }

        if(param.duration !== undefined){
            this.setState({
                paymentQty:parseInt(param.duration)
            })
        }

        if(param.custom_seats !== undefined){
            this.setState({
                paymentQty:parseInt(param.custom_seats)
            })
        }

        
        if(param.method !== undefined){
            switch (param.method) {
                case 'credit_card':
                    context.setState({
                        paymentMethod:1,
                        paymentMethods:'credit_card'
                    })
                    break;
                case 'bank_transfer':
                    context.setState({
                        paymentMethod:2,
                        paymentMethods:'bank_transfer'
                    })
                    break;
                case 'qris':
                    context.setState({
                        paymentMethod:3,
                        paymentMethods:'qris'
                    })
                    break;
                default:
                    break;
            }
        }

        if(param.cancel !== undefined){
            if(param.cancel==='true'){
                console.log('payment canceled')
                // const param1 = ['edu_payment_cancel', param.scheme, param.duration, param.method ]
                // const param2 = {
                //     eventName:'edu_payment_cancel',
                //     pay_scheme:param.scheme,
                //     pay_duration:param.duration,
                //     pay_method:param.method,
                // }
                //sendEvent(param1, param2)
            }

        }

        getDetailPlan(context, context.planId)


    }

    render(){
        const context = this
        var {paymentMode,paymentQty,paymentMethod,paymentMethods,loading,customDuration, plan, id_pricing} = context.state
        //const planId = context.planId
        var customSeats = context.state.customSeats
        var paymentOption = []
        var monthlyPrice = plan.monthly
        var yearlyPrice = plan.yearly
        var activePrice = monthlyPrice

        if(yearlyPrice.length){
            activePrice = yearlyPrice
        }

        var customType = 1

        if(paymentMode===2){
            paymentOption = [
                {title:'1 month',price:(monthlyPrice),qty:1},
                {title:'3 month',price:((monthlyPrice)*3).toFixed(2),qty:3},
                {title:'6 month',price:((monthlyPrice)*6).toFixed(2),qty:6},
                // {title:'1 year',price:(yearlyPrice),qty:12}
            ]
        } else {
            paymentOption = [
                {title:'monthly',price:(monthlyPrice),qty:1},
                {title:'yearly',price:(yearlyPrice),qty:12}
            ]
        }

        var paymentAccepted = false
        if((paymentQty>0 && !customSeats) || paymentQty > 9){
            paymentAccepted = true
        }


        return(
            <div id='container' className='checkout-parent'>
                <Midtrans/>
                <div className='checkout-header'>
                    <div> <BsArrowLeft onClick={()=>context.props.history.replace('/Plans')}/> Checkout</div>
                    <div>
                        {/* <div>Purchase Options </div>
                        <div>Payment Details</div>
                        <div>Confirmation</div> */}
                    </div>
                </div>
                <div className='checkout-info'>
                    <div className='wrap' style={{backgroundColor:plan.backgroundColor, backgroundImage:"linear-gradient(330deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0))"}}>
                        <div className="inner">

                            <div className="header">
                                <img className="icon" alt='' src={plan.image} />
                                <div className="text">
                                    <div className="area-label">Selected Product</div>
                                    <div className="plan-name">{plan.name}</div>
                                </div>
                            </div>
                            <div className="body">
                                <div className="list-title">Comes with</div>
                                <ul className="list-items">
                                    {plan.feature.map((item,index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                                <div style={{display: 'none'}}>
                                    {plan.feature}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='checkout-form'>
                    <div className='checkout-text-menu'>Payment Scheme</div>
                    <div className='checkout-scheme-option-container'>

                        {context.planId !== '20'?(
                            <div className="radio-wrap">
                                <input type="radio"  name="radio" id="scheme-sub" checked={paymentMode===1?true:false}/>
                                <label onClick={()=>{
                                        context.setState({paymentMode:1,paymentQty:paymentQty,paymentMethod:0, customDuration:false}) 
                                        const param1 = ['edu_payment_scheme','subscription']
                                        sendEvent(param1)
                                        context.props.history.replace('/Checkout/'+context.planId+'?scheme=subscription')
                                        const radio = document.getElementsByName('radio2') 
                                        for(var i=0;i<radio.length;i++)radio[i].checked = false;
                                    }} 
                                    className="checkout-scheme-option" for="scheme-sub" >
                                    Subscription
                                    <div>You will be charged monthly with the payment method you choose</div>
                                </label>
                            </div>
                        ):null}
                        <div className="radio-wrap">
                            <input type="radio"  name="radio" id="scheme-one" checked={paymentMode===2?true:false} />
                            <label onClick={()=>{
                                context.setState({paymentMode:2,paymentQty:paymentQty,paymentMethod:0, customDuration:false})
                                const param1 = ['edu_payment_scheme','onetime']
                                sendEvent(param1)
                                context.props.history.replace('/Checkout/'+context.planId+'?scheme=onetime')
                                const radio = document.getElementsByName('radio2')
                                for(var i=0;i<radio.length;i++)radio[i].checked = false;
                            }} className="checkout-scheme-option" for="scheme-one"  >
                                Onetime
                                <div>You only need to pay once for your preferred subscription plan. Pay again if you’d like to extend it</div>
                            </label>
                        </div>

                    </div>

                    {((paymentMode===0 || paymentMode===1) && context.planId!=='20' && !customSeats)?(
                        <div className={paymentMode>0?'checkout-text-menu':'checkout-text-menu grayout'}>
                            <div>Subscription Period</div>
                        </div>
                    ):null}


                    {(context.planId === '20')?(
                        <div className='checkout-text-menu'>
                            <div>Quantity</div>
                        </div>
                    ):null}

                    {(customSeats)?(
                        <div className='checkout-text-menu'>
                            <div>Seats</div>
                        </div>
                    ):null}

                    {(paymentMode===2 && context.planId !== '20' && !customSeats)?(
                        <div className='checkout-text-menu'>
                            <div>Duration</div>
                        </div>
                    ):null}

                    {/* {paymentMode>0?(
                        
                    ):null} */}

                    {context.planId !== '20' ? (
                        <div className={paymentMode>0?'checkout-duration-container':'checkout-duration-container grayout'}>
                            {paymentOption.map( item => {
                                var res = (
                                    <div 
                                        onClick={()=>{
                                            if(paymentMode>0){
                                                const radio = document.getElementsByName('radio2')
                                                for(var i=0;i<radio.length;i++)radio[i].checked = false;
                                                
                                                const param1 = ['edu_payment_duration',item.qty]
                                                sendEvent(param1)
                                                //dev
                                                var scheme = 'subscription'
                                                if(paymentMode===2){
                                                    scheme='onetime'
                                                }   
                                                if(paymentMode===1){
                                                    if(id_pricing.monthly && item.qty === 1){
                                                        context.planId = id_pricing.monthly
                                                    } else if(id_pricing.yearly && item.qty === 12){
                                                        context.planId = id_pricing.yearly
                                                    }
                                                } else if(paymentMode===2){
                                                    scheme='onetime'
                                                    if(id_pricing.monthly){
                                                        context.planId = id_pricing.monthly
                                                    } 
                                                }   
                                                
                                                
                                                context.props.history.replace('/Checkout/'+context.planId+'?scheme='+scheme+'&duration='+item.qty)
                                                context.setState({paymentQty:item.qty,paymentMethod:0})
                                            }
                                        }}
                                        key={item.title} className={paymentQty===item.qty?'checkout-duration-option active':'checkout-duration-option'}>
                                        <div className='checkout-duration-option-title'>{item.title}</div>
                                        <div className='checkout-duration-option-price'>{item.price} USD</div>
                                    </div>
                                )
                                if(item.price < 1 || customDuration || customSeats){
                                    res = null
                                }

                                return res
                            })}
                            {(paymentMode===2 && !customSeats && !customDuration)?(
                                <div
                                    onClick={()=>{
                                        var scheme = 'subscription'
                                        if(paymentMode===2){
                                            scheme='onetime'
                                            if(id_pricing.monthly){
                                                context.planId = id_pricing.monthly
                                            } 
                                        }   

                                        context.props.history.replace('/Checkout/'+context.planId+'?scheme='+scheme+'&duration='+paymentQty)
                                        context.setState({paymentQty:null, customDuration:true})

                                        
                                    }}
                                    key='custom' className='checkout-duration-option'>
                                    <div className='checkout-duration-option-title1'>Custom<br/>Duration</div>
                                </div>
                            ):null}

                            {customDuration?(
                                <div className='checkout-duration-custom'>
                                    <input 
                                        type='number'
                                        defaultValue={paymentQty}
                                        onChange={(e)=>{
                                            if(e.target.value >= 0){
                                                var scheme = 'subscription'
                                                if(paymentMode===2){
                                                    scheme='onetime'
                                                }   
                                                context.setState({
                                                    paymentQty:e.target.value
                                                })
                                                context.props.history.replace('/Checkout/'+context.planId+'?scheme='+scheme+'&duration='+e.target.value)
                                            } else {
                                                e.preventDefault()
                                            }
                                        }}
                                    />
                                    <div>{customType === 1?'month':'year'}</div>
                                    <div>{( ( (activePrice) * paymentQty) - (2 * Math.floor(paymentQty / 12) * (activePrice))).toFixed(2)} USD</div>
                                    <GrClose
                                        onClick={()=>{
                                            context.setState({
                                                customDuration:false
                                            })
                                        }}
                                    />
                                </div>
                            ):null}

                            {customSeats?(
                                <div className='checkout-seats-custom'>
                                    <input 
                                        type='number'
                                        defaultValue={paymentQty}
                                        onChange={(e)=>{
                                            if(e.target.value >= 0){
                                                var scheme = 'subscription'
                                                if(paymentMode===2){
                                                    scheme='onetime'
                                                }   
                                                context.setState({
                                                    paymentQty:e.target.value
                                                })
                                                context.props.history.replace('/Checkout/'+context.planId+'?scheme='+scheme+'&duration='+e.target.value)
                                            } else {
                                                e.preventDefault()
                                            }
                                        }}
                                    />
                                    <div>Seats</div>
                                    <div>{paymentQty>=60?paymentQty*activePrice[2]:paymentQty>=30?paymentQty*activePrice[1]:paymentQty>9?paymentQty*activePrice[0]:0}  USD <br/><span>/ year</span></div>
                                </div>
                            ):null}
                        </div>
                    ):null}

                    
                    {context.planId === '20'? (
                        <div className='checkout-quantity-form'>
                            <input 
                            value={paymentQty}
                            id='product-quantity'
                            onChange={(e)=>{
                                if(e.target.value >= 0){

                                    context.setState({
                                        paymentQty:e.target.value
                                    })
                                }


                            }}
                            type='number'/>

                            <div>{(parseInt(paymentQty)*3)}$</div>
                        </div>
                    ):null}

                    {customSeats?(
                        <div className='checkout-seats-custom-option'>
                            <div className={(paymentQty<30 && paymentQty>9)?'active':''}>
                                <div>10 - 29 seats</div>
                                <div>${activePrice[0]} / year / seats</div>
                            </div>
                            <div className={(paymentQty<60 && paymentQty>29)?'active':''}>
                                <div>30 - 59 seats</div>
                                <div>${activePrice[1]} / year / seats</div>
                            </div>
                            <div className={paymentQty>=60?'active':''}>
                                <div>{'> '}60 seats</div>
                                <div>${activePrice[2]} / year / seats</div>
                            </div>
                        </div>
                    ):null}
                    

                    <div className={paymentAccepted?'checkout-text-menu':'checkout-text-menu grayout'}>
                        <div>Payment Method</div>
                    </div>
                    

                    

                    <div className={paymentAccepted?'checkout-scheme-option-container':'checkout-scheme-option-container grayout'}>
                        <div className="radio-wrap">
                            <input id='method-cc' disabled={paymentAccepted?false:true} type="radio"  name="radio2" checked={paymentMethod===1?true:false}/>
                            <label 
                                for="method-cc"
                                onClick={()=>{
                                    if(paymentAccepted){
                                        context.setState({paymentMethod:1,paymentMethods:'credit_card'})
                                        const param1 = ['edu_payment_method','credit_card']
                                        sendEvent(param1)
                                        var scheme = 'subscription'
                                        if(paymentMode===2){
                                            scheme='onetime'
                                        }   
                                        context.props.history.replace('/Checkout/'+context.planId+'?scheme='+scheme+'&duration='+paymentQty+'&method=credit_card')
                                    }
                                }}
                                
                                className="checkout-scheme-option">
                                
                                Credit/Debit Card
                                <div>VISA, MASTERCARD, JBC</div>
                            </label>
                        </div>
                        <div className={(paymentMode===2 && paymentAccepted)?"radio-wrap":'radio-wrap grayout'}>
                        
                            <input 
                                id='method-tr' 
                                disabled={(paymentMode===2 && paymentAccepted)?false:true}
                                type="radio"  name="radio2" checked={paymentMethod===2?true:false}/>
                            <label
                                for="method-tr"
                                onClick={()=>{
                                    if(paymentMode===2 && paymentAccepted){
                                        context.setState({paymentMethod:2,paymentMethods:'bank_transfer'})
                                        const param1 = ['edu_payment_method','bank_transfer']
                                        sendEvent(param1)
                                        var scheme = 'subscription'
                                        if(paymentMode===2){
                                            scheme='onetime'
                                        }   
                                        context.props.history.replace('/Checkout/'+context.planId+'?scheme='+scheme+'&duration='+paymentQty+'&method=bank_transfer')
                                    }
                                }} 
                                
                                className={paymentMode===2?"checkout-scheme-option":"checkout-scheme-option disable"}>
                                Bank Transfer
                                <div>BNI, Permata, and other</div>
                            </label>
                        </div>
                        <div className={(paymentMode===2 && paymentAccepted)?"radio-wrap":'radio-wrap grayout'}>
                        
                            <input 
                                id='method-qr' 
                                disabled={(paymentMode===2 && paymentAccepted)?false:true}
                                type="radio"  name="radio2" checked={paymentMethod===3?true:false}/>
                            <label 
                                for="method-qr"
                                onClick={()=>{
                                    if(paymentMode===2 && paymentAccepted){
                                        context.setState({paymentMethod:3,paymentMethods:'qris'})
                                        const param1 = ['edu_payment_method','qris']
                                        sendEvent(param1)
                                        var scheme = 'subscription'
                                        if(paymentMode===2){
                                            scheme='onetime'
                                        }   
                                        context.props.history.replace('/Checkout/'+context.planId+'?scheme='+scheme+'&duration='+paymentQty+'&method=qris')
                                    }
                                }} 
                                className={paymentMode===2?"checkout-scheme-option":"checkout-scheme-option disable"}>
                                QRIS Supported Apps
                                <div>Gopay, Dana, Ovo, Link Aja</div>
                            </label>
                        </div>
                    </div>

                    
                    
                    {loading?(
                        <div className='loader2'/>
                    ):<button 
                        disabled={paymentMethod>0?false:true}
                        onClick={(e)=>{
                            //console.log(context.planId)
                            context.setState({loading:true})
                            var paymentScheme = ''

                            if(paymentMode === 1){
                                paymentScheme = 'subscription'
                            } else {
                                paymentScheme = 'onetime'
                            }
                            
                            const param1 = ['edu_payment_pay',paymentScheme , paymentQty, paymentMethods]
                            sendEvent(param1)

                            console.log({customDuration1:context.customDuration, customDuration, paymentQty, customSeats, buyMarker:context.buyMarker})

                            setTimeout(async function(){
                                if(paymentMethods==='credit_card'){
                                    //jika tipe pembeliannya subscription
                                    if(paymentMode===1){
                                        //jika durasinya custom dan bukan beli custom seats 
                                        if(context.customDuration || customDuration || (paymentQty !== 1 && paymentQty !== 12 && !customSeats && !context.buyMarker)){
                                            checkoutCustomDurationPlan(e,context,"1",context.planId)
                                        } else {
                                            console.log('ko kesini')
                                            checkoutPlan(e,context,"1",context.planId)
                                        }
                                    } 
                                    //jika tipe pembeliannya onetime dan menggunakan kartu kredit
                                    else {
                                        if(context.customDuration || customDuration || (paymentQty !== 1 && paymentQty !== 12  && !customSeats && !context.buyMarker)){
                                            checkoutCustomDurationPlan(e,context,"3",context.planId)
                                        } else {
                                            checkoutPlan(e,context,"3",context.planId)
                                        }
                                    }
                                    
                                } else {
                                    //buyPlansMidtrans(context,parseInt(context.planId),paymentMethods)
                                    console.log(customDuration+ ' , '+paymentQty+ ' , '+customSeats)
    
                                    if(context.customDuration || customDuration || (paymentQty !== 1 && paymentQty !== 12 && (customSeats === undefined || customSeats === false) && (context.buyMarker === undefined || context.buyMarker === false)) ){
                                        console.log('beli custom')
                                        checkoutCustomDurationPlan(e,context,"2",context.planId)
                                    } else {
                                        console.log('ko kesini')
                                        checkoutPlan(e,context,"2",context.planId)
                                    }
                                }
                            },500)

                            //buyPlansMidtrans(context,parseInt(context.planId),paymentMethods)
                            
                            
                        }}
                        className={paymentMethod>0?'checkout1-submit':'checkout1-submit grayout'}>Proceed
                    </button>}

                    


                    

                    
                    
                </div>
                
                



            </div>
        )
    }
}

function mapDispatchToProps(dispatch){
    return {
        updateTopic : (data) => dispatch({type:'UPDATE_TOPIC',data:data}),
        updatePlan : (data) => dispatch({type:'UPDATE_PLAN',data:data}),
        changeLanguage : (data) => dispatch({type:'CHANGE_LANGUAGE',data:data}),
    }
}
const mapStateToProps = (state) => {
    return {
        redux : state
    }
}



export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Checkout))